
const SERVER_URL= 'https://www.qdcloud.net:8443';  //QDCloud正式服务器
const APP_ID='wx40ac5448068f67e6' //微信登录appid
// const SERVER_URL='http://192.168.1.185:8080' //本地服务器

//项目ID
const config={
    SERVER_URL,
    APP_ID,

}

export default config;

