import React from 'react';
import {
    Row,
    Col,
    Card,
    Button
} from 'antd';
import './style.css';
import banner from '../../statics/solution/banner_01.png';
import stack from '../../statics/solution/ico_stack.svg';
import thumb from '../../statics/solution/ico_thumb.svg';
import star from '../../statics/solution/ico_star.svg';
import team from '../../statics/solution/ico_team.svg';
import reason from '../../statics/solution/img_reason.png';
import sample1 from '../../statics/solution/img_sample_01.png';
import function1 from '../../statics/solution/img_function_01.png';
import function2 from '../../statics/solution/img_function_02.png';
import function3 from '../../statics/solution/img_function_03.png';
import backImg from '../../statics/solution/bg_violet_01.png';
import backImg2 from '../../statics/solution/bg_grey_01.png';
import {wbGetLaunchedCaseList} from "../../config/api";
import TabsComponent from "../../component/TabsComponent";
import logo from '../../statics/solution/Logo-gxsj.png';
import service1 from '../../statics/solution/service_bg_01.png';
import service2 from '../../statics/solution/service_bg_02.png';
import service3 from '../../statics/solution/service_bg_03.png';
import service4 from '../../statics/solution/service_bg_04.png';
import service5 from '../../statics/solution/service_bg_05.png';

import service1_1 from '../../statics/solution/ico_book.svg';
import service2_1 from '../../statics/solution/ico_setting.svg';
import service3_1 from '../../statics/solution/ico_team.svg';
import service4_1 from '../../statics/solution/ico_medal.svg';
import service5_1 from '../../statics/solution/ico_crown.svg';

import PaddingWrapper from "../../component/PaddingComponent";
import paddingEnum from "../../component/enum/paddingEnums";
import buttonIco from "../../statics/solution/ico_fly_white.svg";
import ButtonWrapper from "../../component/ButttonComponent";
import ButtonType from "../../component/enum/buttonEnum";
import ReasonBg from "../../statics/solution/img_reason_bg.png";
import ImageCase01 from "../../statics/solution/img_case_01.png";
import CardWrapper from "../../component/CardComponent";
import ShowAnimate from "../../common/showAnimate";
import cardEnum from "../../component/enum/cardEnum";
import mobxstore from "../../mobxStore/store";
import {reaction} from "mobx";
import _ from "lodash";
import config from "../../config";

const {Meta} = Card;
const metastyle = {
    textAlign: 'center',
    padding: '15px',
}

const ButtonBox = (props) => {
    return (
        <a style={props.style} className='button-box'>{props.children}</a>
    );
};

export default class SolutionHead extends React.Component {
    constructor(props) {
        super(props);
        const cardObj2 = [
            {
                images: ImageCase01,
                images2: logo,
                description: '好的界面设计并不始于图片，而是始于对人的理解，比如人们喜欢什么，为什么人们会试用某种特定的软件，他们可能与之怎样交互',
                type: cardEnum.IMAGE_ICON_DETAIL
            },
            {
                images: ImageCase01,
                images2: logo,
                description: '如果你既不热爱你的工作，也不对之嗤之以鼻，那说明你做的还不够多',
                type: cardEnum.IMAGE_ICON_DETAIL
            },
            {
                images: ImageCase01,
                images2: logo,
                description: '那些更棒的点子来自于个体依然用过去习惯的方式思考创意时——坐在办公桌前时，在咖啡店等咖啡时，洗澡时。这些由人们独自想出的点子更胜一筹。当讨论会的狂热劲头散去，头脑风暴产生的点子其实并没有那么特别',
                type: cardEnum.IMAGE_ICON_DETAIL
            }
        ]
        const tabsObjArr = [{
            caseName: '快速搭建',
            image: function1,
            style: {height: "100%"}
        }, {
            caseName: '直播卖货',
            image: function2,
            style: {height: "100%"}
        }, {
            caseName: '会员管理',
            image: function3,
            style: {height: '100%'}
        }]
        this.state = {
            cardObj2,
            list: [],
            tabsObjArr
        }
    }

    /**
     * 异步请求解决方案数据
     */
    componentDidMount() {
        // getSolutionList().then(res => {
        //         this.setState({list: res.content});
        //     })

        this.getCardObj2()

    }

    getCardObj2 = () => {
        // 获取导航栏路径
        const path = window.location.hash.replace('#', '');
        this.interval = setInterval(() => {
            let menuList = JSON.parse(JSON.stringify(mobxstore.menuList))
            if (menuList && menuList.length > 0) {
                let currentMenu = menuList.find((item) => item.routeUrl == path)
                wbGetLaunchedCaseList(currentMenu.solutionCategoryId).then((res) => {
                    this.setState({list: res.data.itemList});

                    let cardObj = _.map(res.data.itemList, ({caseImageUrl, customerLogoUrl, description}) => ({
                        images: config.SERVER_URL + caseImageUrl,
                        images2: config.SERVER_URL + customerLogoUrl,
                        description,
                        type: cardEnum.IMAGE_ICON_DETAIL
                    }));
                    this.setState({cardObj2: cardObj})
                })
                clearInterval(this.interval)
            }

        }, 500)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    renderBox = (src1, src2, title, detail) => {
        return (
            <div className='list-box-a' style={{position: 'relative'}}>
                <img src={src1} style={{width: '100%'}}/>
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}>
                    <img src={src2} style={{width: '2rem'}}/>
                </div>
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, 80%)',
                    fontWeight: 'bolder',
                    color: 'white',
                    marginTop: '0.5rem',
                    fontSize: '20px'
                }}>
                    {title}
                </div>

                <div style={{
                    position: 'absolute',
                    top: '240px',
                    width: '90%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    color: 'white', marginTop: '0.5rem',
                    textAlign: 'center',
                    fontSize: '14px'
                }}>
                    {detail}
                </div>
            </div>
        )
    }

    render() {
        const {tabsObjArr, cardObj2} = this.state
        const displayWidth = 1200
        return (
            <div className="solutionHead">
                <Row type="flex" justify="center">
                    <Col span={24}>
                        <div className="banner-join" style={{position: 'relative'}}>
                            <img src={banner} className="img-banner" alt="banner solution"/>
                        </div>
                    </Col>
                </Row>

                <div className='solution-desc-style'>
                    <div className='banner-font'>电商小程序解决方案</div>
                    <div style={{marginTop: '1.4rem'}}>
                        <div className='solution-middle-font' style={{width: '500px', textAlign: 'center'}}>
                            迅速拥有自己的小程序商城，构筑自己的私域流量，迅速达成业绩小目标！
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <div className='solution-icon-box'>
                            <img style={{width: '40%', height: '40%'}} src={stack}/>
                            <span style={{
                                fontSize: '17px',
                                color: 'white',
                                fontWeight: 'bolder',
                                marginTop: '1rem'
                            }}>零开发</span>
                        </div>
                        <div className='solution-icon-box'>
                            <img style={{width: '40%', height: '40%'}} src={thumb}/>
                            <span style={{
                                fontSize: '17px',
                                color: 'white',
                                fontWeight: 'bolder',
                                marginTop: '1rem'
                            }}>高效引流</span>
                        </div>
                        <div className='solution-icon-box'>
                            <img style={{width: '40%', height: '40%'}} src={star}/>
                            <span style={{
                                fontSize: '17px',
                                color: 'white',
                                fontWeight: 'bolder',
                                marginTop: '1rem'
                            }}>复购提升</span>
                        </div>
                        <div className='solution-icon-box'>
                            <img style={{width: '40%', height: '40%'}} src={team}/>
                            <span style={{
                                fontSize: '17px',
                                color: 'white',
                                fontWeight: 'bolder',
                                marginTop: '1rem'
                            }}>用户粘性高</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                        <ButtonWrapper
                            buttonStyle={{marginTop: '3.5rem'}}
                            type={ButtonType.MIAN_FEI_SHI_YONG_WHITE}
                        >免费试用</ButtonWrapper>
                        <div style={{width: '15px'}}></div>
                        {/*<ButtonWrapper*/}
                        {/*    buttonStyle={{marginTop: '3.5rem'}}*/}
                        {/*    type={ButtonType.ZAI_XIAN_ZI_XUN}*/}
                        {/*>在线咨询</ButtonWrapper>*/}
                    </div>

                </div>

                <div className='solution-section'>
                    <div className='solution-wrap' style={{
                        width: displayWidth + 'px',
                        marginLeft: (window.screen.width - displayWidth) / 2 + 'px'
                    }}>
                        <div style={{width: displayWidth / 2 + 'px'}}>
                            <div className='solution-section-title'>
                                你想要的小程序这里都有
                            </div>
                            <div style={{marginTop: '4rem'}}>
                                <div style={{
                                    fontSize: '20px',
                                    fontWeight: 'bolder',
                                    color: 'black',
                                    marginBottom: '0.5rem'
                                }}>社交电商
                                </div>
                                <div style={{display: 'flex'}}>
                                    <ButtonBox style={{marginRight: '1rem'}}>小程序商城</ButtonBox>
                                    <ButtonBox>会员管理</ButtonBox>
                                </div>
                            </div>

                            <div style={{marginTop: '2rem'}}>
                                <div style={{
                                    fontSize: '20px',
                                    fontWeight: 'bolder',
                                    color: 'black',
                                    marginBottom: '0.5rem'
                                }}>零售门店
                                </div>
                                <div style={{display: 'flex'}}>
                                    <ButtonBox style={{marginRight: '1rem'}}>客户管理</ButtonBox>
                                    <ButtonBox style={{marginRight: '1rem'}}>积分商城</ButtonBox>
                                    <ButtonBox style={{marginRight: '1rem'}}>扫码点单</ButtonBox>
                                    <ButtonBox style={{marginRight: '1rem'}}>会员储值</ButtonBox>
                                </div>
                            </div>

                            <div style={{marginTop: '2rem'}}>
                                <div style={{
                                    fontSize: '20px',
                                    fontWeight: 'bolder',
                                    color: 'black',
                                    marginBottom: '0.5rem'
                                }}>行业定制
                                </div>
                                <div style={{display: 'flex'}}>
                                    <ButtonBox style={{marginRight: '1rem'}}>服装配饰</ButtonBox>
                                    <ButtonBox style={{marginRight: '1rem'}}>生鲜蔬果</ButtonBox>
                                    <ButtonBox style={{marginRight: '1rem'}}>日用百货</ButtonBox>
                                    <ButtonBox style={{marginRight: '1rem'}}>美妆护肤</ButtonBox>
                                    <ButtonBox>蛋糕烘焙</ButtonBox>
                                </div>
                            </div>
                        </div>
                        <div style={{width: displayWidth / 2 + 'px', height: '636px', position: 'relative'}}>
                            <img src={sample1}
                                 style={{height: '662px', position: 'absolute', bottom: '-100px', left: '100px'}}/>
                        </div>
                    </div>
                </div>

                <div style={{backgroundImage: 'url(' + ReasonBg + ')', backgroundSize: 'cover'}}>
                    <div style={{
                        width: displayWidth + 'px',
                        marginLeft: (window.screen.width - displayWidth) / 2 + 'px'
                    }}>
                        <header className='hot-title' style={{
                            paddingTop: '6rem',
                            paddingBottom: '4rem',
                            color: 'white',
                            textAlign: 'start'
                        }}>做小程序商城的6大理由
                        </header>
                        <img src={reason} style={{width: '100%', marginTop: '1.5rem'}}/>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <ButtonWrapper
                                buttonStyle={{marginTop: '2rem'}}
                                type={ButtonType.MIAN_FEI_SHI_YONG}
                            >免费试用</ButtonWrapper>
                        </div>
                        <div style={{height: '60px'}}></div>
                    </div>

                </div>

                <div>
                    <TabsComponent
                        title='丰富多样的商城功能，助力收割流量红利' tabsObjArr={tabsObjArr}
                        onlyShowImage={true}
                        size='large'
                        tabStyle={{backgroundColor:'#f3f3f3'}}
                    />
                </div>

                <div>
                    {/*<header className='hot-title' style={{*/}
                    {/*    paddingTop: '3rem',*/}
                    {/*    paddingBottom: '1rem'*/}
                    {/*}}>他们都在用小程序*/}
                    {/*</header>*/}
                    <ShowAnimate>
                        <CardWrapper
                            cardObj={cardObj2}
                            title='他们都在用小程序'
                            cardStyle={{backgroundColor: '#fff'}}
                            titlePosition='left'
                        />
                    </ShowAnimate>

                    {/*<div style={{display: 'flex', width: '76%', marginLeft: '12%'}}>*/}
                    {/*    <div className='list-box'>*/}
                    {/*        <img src={logo} className='logo-style'/>*/}
                    {/*        <div*/}
                    {/*            className='list-font'>借助起典电商小程序快速切入在腾讯的零售布局，快速实现了去中心化流量聚合，客户粉丝沉淀。起典助力青浦供销社快速步入移动社交电商新时代。*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className='list-box'>*/}
                    {/*        <img src={logo} className='logo-style'/>*/}
                    {/*        <div*/}
                    {/*            className='list-font'>借助起典电商小程序快速切入在腾讯的零售布局，快速实现了去中心化流量聚合，客户粉丝沉淀。起典助力青浦供销社快速步入移动社交电商新时代。*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className='list-box'>*/}
                    {/*        <img src={logo} className='logo-style'/>*/}
                    {/*        <div*/}
                    {/*            className='list-font'>借助起典电商小程序快速切入在腾讯的零售布局，快速实现了去中心化流量聚合，客户粉丝沉淀。起典助力青浦供销社快速步入移动社交电商新时代。*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*</div>*/}
                </div>

                <div style={{height: '50px', backgroundColor: 'white'}}></div>

                <div style={{backgroundColor: '#f3f3f3', paddingBottom: '5rem'}}>
                    <header className='hot-title' style={{
                        paddingTop: '6rem',
                        paddingBottom: '3rem',
                        color: '#000000'
                    }}>我们全程助力，持续赋能，只为达成您的年度小目标
                    </header>
                    <div style={{
                        width: displayWidth + 'px',
                        marginLeft: (window.innerWidth - displayWidth) / 2 + 'px',
                        display: 'flex'
                    }}>
                        {this.renderBox(service1, service1_1, '经营策略', '行业市场及目标人群分析，深挖需求痛点，定制解决方案。')}
                        {this.renderBox(service2, service2_1, '系统工具', '提供高效且有用的系统及工具，支持定制和对接原系统。')}
                        {this.renderBox(service3, service3_1, '服务团队', ' 专业运营团队陪跑式服务，随时监督和指导，贡献运营建议。')}
                        {this.renderBox(service4, service4_1, '广告资源', '海量优质广告资源位提供，营销活动精准触达目标客户群')}
                        {this.renderBox(service5, service5_1, '定制开发', '10年技术沉淀，提供个性化高品质定制服务，安全持续保障。')}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <ButtonWrapper
                            buttonStyle={{marginTop: '8rem'}}
                            type={ButtonType.MIAN_FEI_SHI_YONG}
                        >免费试用</ButtonWrapper>
                    </div>

                </div>

                {/*<div className="solution-meddle">*/}
                {/*    <Row type="flex" justify="center">*/}
                {/*        <Col lg={16} xl={14} sm={20}  xs={20} >*/}
                {/*            <p>QDCloud云平台是起典软件经过多年行业内的耕耘及对物联网行业的深刻理解,推出的面向个人及企业开发者的一站式云服务平台。</p>*/}

                {/*            <Row type="flex" justify="center" >*/}
                {/*                <Col span={6} >*/}
                {/*                    <img src={dev} alt="dev"/>*/}
                {/*                    <span>应用开发</span>*/}
                {/*                </Col>*/}
                {/*                <Col span={6} >*/}
                {/*                    <img src={cloud} alt="dev"/>*/}
                {/*                    <span>云端开发</span>*/}
                {/*                </Col>*/}
                {/*                <Col span={6} >*/}
                {/*                    <img src={mng} alt="dev"/>*/}
                {/*                    <span>运营管理</span>*/}
                {/*                </Col>*/}
                {/*                <Col span={6} >*/}
                {/*                    <img src={data} alt="dev"/>*/}
                {/*                    <span>数据服务</span>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</div>*/}

                {/*<div className="solution-bottom">*/}
                {/*    <Row type="flex" justify="center">*/}
                {/*        <Col span={19} >*/}
                {/*            <h1>解决方案</h1>*/}
                {/*            <Row type="flex" justify="center" gutter={18}>*/}
                {/*                {this.state.list.map((item,index) => {*/}
                {/*                    return (*/}
                {/*                        <Col span={11} className="gutter-row solutioncase" key={index} style={{paddingRight:'15px'}}>*/}
                {/*                            <Card*/}
                {/*                                hoverable                                        */}
                {/*                                cover={<img*/}
                {/*                                            alt="example"*/}
                {/*                                            src={`${config.SERVER_URL}${item.originalImageUrlList[0]}`}/>}*/}
                {/*                                            onClick={()=>{this.props.history.push(`/solution/case/detail?id=${item.caseId}`)}}*/}
                {/*                            >*/}
                {/*                                <Meta */}
                {/*                                    style={metastyle}*/}
                {/*                                    title={item.caseName}*/}
                {/*                                    description={item.caseDescription}*/}
                {/*                                />                                           */}
                {/*                            </Card>*/}
                {/*                        </Col>*/}
                {/*                    )*/}
                {/*                })}*/}
                {/*                */}

                {/*            </Row>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</div>*/}
            </div>
        );
    }
}