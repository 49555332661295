import React, { Component } from 'react';
import {
	Row,
	Col,
	Button,
	Form,
	Input, Icon,
	message,
} from 'antd';
import './style.css';
import {Link} from "react-router-dom";
import {sendVerifyCodeAsSms} from "../../config/api";
import classNames from "classnames";
import axios from "axios";
import config from "../../config";

const FormItem = Form.Item;

class ForgetPwd extends Component {
	constructor(props) {
		super(props);

		this.state={
			waitTime:0
		}
	}
	validateInput = (_, value) => {
		// 正则表达式校验规则
		const regExp = /^[a-zA-Z0-9]{6,21}$/;

		if (value && !regExp.test(value)) {
			return Promise.reject('请输入6到21位的数字或字母');
		}

		return Promise.resolve();
	};

	handleSubmit=()=>{
		this.props.form.validateFields((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
			}
		});

		if (this.state.currentPassword !== this.state.currentPasswordVerify) {
			message.error('两次输入的密码不一致！');
			console.log("两次输入的密码不一致！");
			return;
		}
		axios.post(`${config.SERVER_URL}/rest/services/qdUpdatePassword`, {
			"userLoginId": this.state.phoneNumber,  //用户名(手机)，必填
			"verifyCode": this.state.verifyCode,  //验证码 ，必填
			"newPassword": this.state.currentPassword,  //密码 ，必填
			"newPasswordVerify": this.state.currentPasswordVerify,  //验证密码 ，必填
		})
			.then(response => {
				this.props.history.push('/login');
				message.success('密码修改成功！');
				// if (response.data.httpCode === 200) {
				// 	alert(response.data.msg);
				// 	return false;
				// }
			})
			.catch(error => {
				message.error('密码修改失败！');
				console.log(error);
			});
	}

	sendVerifyCode(){
		this.props.form.validateFields(['phoneNumber'],(err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
			}
		});

		clearTimeout(this.timer)
		clearInterval(this.inter)
		let phoneNumber=this.state.phoneNumber
		let templateTypeId="FORGET_PASSWORD"
		this.timer=setTimeout(()=>{
			sendVerifyCodeAsSms({phoneNumber,templateTypeId}).then((res)=>{
				this.setState({waitTime:59})
				this.inter=setInterval(()=>{
					if(this.state.waitTime>=1){
						this.setState({waitTime:--this.state.waitTime})
					}else {
						clearInterval(this.inter)
					}
				},1000)
			})
		},500)
	}

	// 绑定表单值
	handleChange(key, e) {
		this.setState({
			[key]: e.target.value
		}, () => console.log(this.state));

	}
	render(){
		const { getFieldDecorator } = this.props.form;
		const{ waitTime } = this.state
		const verticalButtonClass = classNames('get-verification-code', {
			'btn-disable': waitTime>0,
		});
		return(
			<div className="forget">
				<Row type="flex" justify="center">
					<Col span={6} >

						<div style={{display:'flex',justifyContent:'space-between',paddingTop:'8rem',paddingBottom:'1rem'}}>
							<div style={{fontWeight: 'bolder', fontSize: '1.5rem'}}>忘记密码</div>
							<div style={{display: 'flex', alignItems: 'center'}}>
								<Link to="/login">
									<span className='register-login'>登录</span>
									<span style={{color: '#20aee5'}}><Icon type="caret-right"/></span></Link>
							</div>
						</div>

						<Form layout='vertical'>
							<FormItem
								label="手机号（用户名）"
							>
								{getFieldDecorator('phoneNumber', {
									rules: [{ required: true, message: '请输入手机号!' }], // 校验规则
								})(
									<Input
										className="input"
										allowClear={true}
										placeholder="请输入您的手机号码"
										onChange={value => this.handleChange('phoneNumber', value)}
									/>
								)}
							</FormItem>

							<FormItem
								label="验证码"
							>
								{getFieldDecorator('verifyCode', {
									rules: [{ required: true, message: '请输入验证码!' }], // 校验规则
								})(
									<div style={{position:'relative'}}>
										<Input
											maxLength={15}
											className="input"
											placeholder="请输入验证码"
											onChange={value => this.handleChange('verifyCode', value)}
										/>
										<div className={verticalButtonClass} onClick={()=>{this.sendVerifyCode()}}>{waitTime>0?`${waitTime} s`:'获取验证码'}</div>
									</div>
								)}
							</FormItem>

							<FormItem
								label="设置新密码"
							>
								{getFieldDecorator('currentPassword', {
									rules: [
										{required: true,
											message:'请输入新密码',
										},
									],
								})(
									<Input.Password
										maxLength={21}
										className="input"
										rules={[
											{
												validator: this.validateInput,
											},
										]}
										placeholder="6到21位数字或字母"
										onChange={value => this.handleChange('currentPassword', value)}
									/>
								)}
							</FormItem>

							<FormItem
								label="再次输入新密码"
							>
								{getFieldDecorator('currentPasswordVerify', {
									rules: [{ required: true, message: '请再次输入密码!' }], // 校验规则
								})(
									<Input.Password
										className="input"
										placeholder="再次输入新密码"
										onChange={value => this.handleChange('currentPasswordVerify', value)}
									/>
								)}
							</FormItem>


				          <FormItem >
				            <Button shape='round' className="btn-login" type="primary" onClick={this.handleSubmit}>提交</Button>
				          </FormItem>

				        </Form>
					</Col>
				</Row>
			</div>
		);
	}
}

export default Form.create()(ForgetPwd)