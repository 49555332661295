import React, {useEffect, useState} from 'react';
import style from './style.module.css'
import indexImg from "../../statics/product/banner_01.png";
import ico1 from '../../statics/product/ico_function.svg';
import ico2 from '../../statics/product/ico_money.svg';
import ico3 from '../../statics/product/ico_service.svg';
import ico4 from '../../statics/product/ico_system.svg';
import bannerProduct from '../../statics/product/banner_01_product.png';
import {Col, Row} from "antd";
import CardWrapper from "../../component/CardComponent";
import cardEnum from "../../component/enum/cardEnum";
import TabsComponent from "../../component/TabsComponent";
import ShowAnimate from "../../common/showAnimate";
import pdTable from '../../statics/product/pdTable.png';
import ButtonWrapper from "../../component/ButttonComponent";
import ButtonType from "../../component/enum/buttonEnum";
import functionWMS from '../../statics/product/img_function_OMS.svg';
import functionOMS from '../../statics/product/img_function_WMS.svg';
import advantage1 from '../../statics/index_2/draw_01.svg';
import advantage2 from '../../statics/index_2/draw_02.svg';
import advantage3 from '../../statics/index_2/draw_03.svg';
import advantage4 from '../../statics/index_2/draw_04.svg';
const ProductDisplay = (props) => {

    const displayWidth = 1200
    const cardObj = [
        {
            title: '全渠道订单同步',
            type: cardEnum.IMAGE_TITLE_DETAIL,
            images: advantage1,
            imageStyle: {width: '100%', height: '50%',padding:'16px'},
            detail: '全渠道电商平台免费对接\n' +
                '商品、订单实时同步',
            boxStyle: {backgroundColor: 'white', paddingTop: '16px', borderRadius: '8px'}
        },
        {
            title: '全渠道一盘货',
            type: cardEnum.IMAGE_TITLE_DETAIL,
            images: advantage2,
            imageStyle: {width: '100%', height: '50%',padding:'16px'},
            detail: '库存管理与共享，降低库存成本，\n' +
                '提升库存周转率，提高发货时效',
            boxStyle: {backgroundColor: 'white', paddingTop: '16px', borderRadius: '8px'}
        },
        {
            title: '无纸化作业',
            type: cardEnum.IMAGE_TITLE_DETAIL,
            images: advantage3,
            imageStyle: {width: '100%', height: '50%',padding:'16px'},
            detail: '拣货、验货、称重、发货，\n' +
                '全流程无纸化扫码作业',
            boxStyle: {backgroundColor: 'white', paddingTop: '16px', borderRadius: '8px'}
        },
        {
            title: '智能审单',
            type: cardEnum.IMAGE_TITLE_DETAIL,
            images: advantage4,
            imageStyle: {width: '100%', height: '50%',padding:'16px'},
            detail: '自动匹配商品、仓库快递、赠品等，\n' +
                '自动合单，智能审单，精准高效',
            boxStyle: {backgroundColor: 'white', paddingTop: '16px', borderRadius: '8px'}
        },

    ]

    let tabsObjArr = [{
        style:{width:'100%'},
        title: '订单管理（OMS）',
        describe: '- 订单实时同步，- 多平台方便管理,- 订单批量审核，- 智能甄别异常订单,- 同步更新修改订单，- 及时拦截异常,- 自动拆分、合并订单,- 赠品配送,- 智能匹配快递',
        image: functionOMS
    }, { style:{width:'100%'},title: '仓储管理（WMS）',image: functionWMS,describe: '- 多平台、多店铺库存，实时同步,'+
            '- 精准库存，缺货预警不超卖,\n' +
            '- 进销库存一目了然,\n' +
            '- 手持PDA全仓无纸化操作,\n' +
            '- 拣货路径合理化,\n' +
            '- 支持员工绩效考核'} ]
    const {} = props

    const [bannerProductHeight, setBannerProductHeight] = useState(0)
    useEffect(() => {
        //获取bannerImg的高度
        setTimeout(() => {
            let bannerImg = document.querySelector('.img-banner')
            if (bannerImg.clientHeight) {
                setBannerProductHeight(bannerImg.clientHeight)
                // this.setState({bannerHeight:bannerImg.clientHeight})
            }

        }, 100)
    }, [])
    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Col span={24} className={style.banner}>
                    <img src={indexImg} style={{position: 'relative'}} alt="index-img" className="img-banner"/>
                    <div className={`${style.fontStyle} index-position-center`}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div className='banner-font'>起典云电商ERP</div>
                            <div className='banner-font-middle' style={{
                                width:displayWidth-100+'px',
                                fontSize: '32px',
                                marginTop: '24px',
                                textAlign: 'center',
                                lineHeight: '48px'
                            }}>起典云SaaS
                                ERP，包括起典云电商SaaS ERP、起典云WMS，和其他提升电商企业经营效率的产品
                            </div>
                            <ButtonWrapper
                                buttonStyle={{marginTop: '56px'}}
                                type={ButtonType.LI_JI_ZI_XUN_WHITE}
                            >立即咨询</ButtonWrapper>
                        </div>
                    </div>

                </Col>
                <img className='banner-product' style={{
                    position: 'absolute',
                    width: displayWidth + 'px',
                    top:'470px'
                }} src={bannerProduct}/>
            </div>

            <div style={{width: '100%', height: '520px'}}></div>


            <ShowAnimate>
                <CardWrapper
                    cardObj={cardObj}
                    title='系统功能优势'
                />
            </ShowAnimate>

            <ShowAnimate>
                <TabsComponent title='核心功能' tabsObjArr={tabsObjArr} paneStyle={{marginTop: '5rem'}}/>
            </ShowAnimate>

            <ShowAnimate>
                <CardWrapper
                    title='版本介绍'
                    cardStyle={{backgroundColor:'white'}}
                >
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <div style={{width:'488px',display:"flex",flexWrap:'wrap',flexDirection:'column',justifyContent:'center',textAlign:'center'}}>
                            <div style={{fontWeight: 'bolder', fontSize: '24px'}}>企业版</div>
                            <div style={{marginTop:'20px',fontSize:'16px'}}>- 适用于对订单、仓库具有较高要求的商家和第三方物流仓库的需要<br/>
                                - OMS+WMS的完整版</div>
                        </div>
                    </div>

                    <div style={{display:'flex',justifyContent:'center'}}>
                        <img src={pdTable} style={{width: displayWidth-200+'px', marginTop: '2rem',marginBottom:'64px'}}/>
                    </div>

                </CardWrapper>
            </ShowAnimate>

            {/*<PaddingWrapper type={paddingEnum.BOTTOM} value={200}/>*/}

        </div>
    );
}

export default ProductDisplay;
