import axios from 'axios';
import {message} from 'antd';
import config from "../config";

axios.defaults.timeout = 5000;
// axios.defaults.baseURL = 'http://116.62.23.7:3001/QDCloud';
axios.defaults.baseURL = config.SERVER_URL;
//http request 拦截器
axios.interceptors.request.use(
    config => {
        // const token = getCookie('名称');注意使用的时候需要引入cookie方法，推荐js-cookie
        // config.data = JSON.stringify(config.data);
        // config.headers = {
        //    Authorization : sessionStorage.getItem('token'),
        //   'Content-Type':'application/json'
        // }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);


//http response 拦截器
axios.interceptors.response.use(
    res => {
        if (res.data.httpCode === 500) {
            message.warn(res.data.msg);
            return Promise.reject(res.data.msg)
        } else if (res.data.httpCode == 401) {
            sessionStorage.removeItem("isLogged")
            this.props.history.push('/');
        }
        return res;
    },
    err => {
        return Promise.reject(err)
    }
)


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data)
            .then(res => {
                resolve(res.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(res => {
                resolve(res.data);
            }, err => {
                reject(err)
            })
    })
}
