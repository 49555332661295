/**
 * tabs组件
 */
import React, {useEffect} from 'react';
import {Tabs, Row, Col} from 'antd';
import style from './style.module.css';

const TabsComponent = (props) => {
    const {TabPane} = Tabs;
    const {title, detail, tabsObjArr, onlyShowImage, paneStyle, tabStyle, contentStyle, size} = props
    const displayWidth = 1200

    function callback(key) {
        // console.log(key);
    }

    // useEffect(()=>{
    //     setTimeout(()=>{
    //         // let tabs=document.querySelector('.ant-tabs-nav')
    //         // let tabsContainer=tabs.firstChild
    //         // tabsContainer.style.display='flex';
    //         // tabsContainer.style.justifyContent='space-between';
    //         // tabsContainer.style.width=displayWidth+'px'
    //         //
    //         // console.log('sss',tabs,tabsContainer)
    //
    //     },1000)
    //
    // },[])

    const renderDesc = (desc) => {
        if (desc) {
            let lineDescArr = desc.split(',')
            return lineDescArr.map((item, index) => (
                <div key={'tab_' + index} style={{lineHeight: '30px', fontSize: '14px'}}>{item}</div>
            ))
        } else {
            return desc
        }

    }
    return (
        <div style={tabStyle}>
            <header className='hot-title' style={{
                paddingTop: '6rem',
                paddingBottom: '1rem'
            }}>{title}</header>

            <div style={{
                textAlign: 'center',
                width: '25%',
                marginLeft: '37.5%',
                fontSize: '12px',
                paddingBottom: '3rem'
            }}>{detail}</div>

            <div style={{
                width: size !== 'large' ? displayWidth + 'px' : '100%',
                marginLeft: size !== 'large' ? (window.screen.width - displayWidth) / 2 + 'px' : ''
            }}>
                <Tabs defaultActiveKey="0" onChange={callback} tabBarStyle={{marginBottom: '0px'}} tabBarGutter={100}>
                    {tabsObjArr && tabsObjArr.length > 0 && tabsObjArr.map((item, index) => {
                        return (
                            <TabPane tab={item.caseName ? item.caseName : item.title} key={index}>
                                <div className={style.container}
                                     style={{
                                         background: 'url(' + item.backImg + ')',
                                         backgroundSize: 'cover', ...paneStyle
                                     }}>
                                    <div>
                                        <Row>
                                            {onlyShowImage ? '' : <Col offset={2} span={6}>
                                                <div style={contentStyle}>
                                                    <div className={style.title}>{item.title}</div>
                                                    <div className={style.describe}>
                                                        {renderDesc(item.describe)}
                                                    </div>
                                                </div>
                                            </Col>}
                                            <Col span={onlyShowImage ? 24 : 16}>
                                                <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                                                    <img src={item.image} style={item.style}/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </TabPane>
                        )
                    })}

                </Tabs>
            </div>
        </div>
    );
}

export default TabsComponent;
