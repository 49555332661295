/**
 * 卡片组件
 */
import React from 'react';
import style from './style.module.css'
import cardEnum from '../enum/cardEnum'

const Card = (props) => {

    const renderDescription=(description)=>{
        let arr=description.split('\n')
        return arr.map((item,index)=>(
            <div key={'cardDesc_'+index}>{item}</div>
        ))
    }
    // const {Meta} = Card;
    const {titleStyle, boxStyle, title, image, image2, description, type, tag, imageStyle} = props
    const renderFirst = () => {
        return (
            <div className={style.box} style={boxStyle}>
                <img
                    style={{
                        ...imageStyle,
                    }}
                    alt=""
                    src={image}
                />
                <div>
                    <div className={style.title} style={titleStyle}>
                        {title}
                    </div>

                    <div title={description} className={style.detail} style={{textAlign: description.length < 15 ? 'center' : ''}}>
                        {renderDescription(description)}
                    </div>
                </div>
            </div>
        )
    }

    const renderSecond = () => {
        return (
            <div className={style.box2} style={boxStyle}>
                <div className={style.imageContainer}>
                    <img
                        className={style.imgStyle}
                        // style={{width: '100%', height: '50%', objectFit: 'cover'}}
                        alt=""
                        src={image}
                    />
                </div>
                <div style={{height: '50%'}}>
                    <div className={style.title2} style={{width: '100%', height: '65%'}}>
                        <div style={{fontSize: '1.2rem', fontWeight: "bolder"}}>{title}</div>
                        <div className={style.tagContent}>
                            {tag && Array.isArray(tag) && tag.map((item, index) => (
                                <div key={'card_'+index} className={style.tag}><span>#</span>{item}</div>
                            ))
                            }
                        </div>
                    </div>

                    <div style={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <img
                            className={style.imageStyle2}
                            // style={{width: '100%', height: '20%'}}
                            alt=""
                            src={image2}
                        />
                    </div>

                    <div className={style.tips}>扫二维码立即体验</div>
                </div>


            </div>
        )
    }

    const renderThird = () => {
        return (
            <div className={style.box3} style={boxStyle}>
                <div className={style.imageContainer3}>
                    <img
                        className={style.imgStyle}
                        // style={{width: '100%', height: '50%', objectFit: 'cover'}}
                        alt=""
                        src={image}
                    />
                </div>

                <div style={{display:"flex",flexDirection:'column',height:'50%'}}>

                    <img src={image2}
                         style={{marginTop: '16px', marginLeft: '24px', width: '64px',height:'64px', borderRadius: '64px'}}/>
                        <div title={description} style={{
                            paddingLeft: '24px',
                            paddingRight: '24px',
                            paddingTop: '24px',
                            paddingBottom:'36px',
                            wordWrap:'break-word',
                            overflow:'hidden',
                            fontSize:'14px',
                            height:'110px'
                        }}>{description}</div>
                </div>


            </div>
        )
    }

    return (
        type === cardEnum.IMAGE_TITLE_DETAIL ? renderFirst()
            : type === cardEnum.IMAGE_TITLE_IMAGE ? renderSecond()
            : type === cardEnum.IMAGE_ICON_DETAIL ? renderThird() : ''
    );
}

export default Card;
