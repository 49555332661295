import {postData,postDataWithoutToken} from "../common/axios/request";

//新建申请试用
export function createCustomerRequest({name,phone,companyName,province,city,district,address,description})
{
    let jsonData = {
        "contactPartyName": name,  //姓名
        "contactNumber": phone, //电话
        "entPartyName": companyName, //公司名
        "provinceGeoId": province, //省
        "cityGeoId": city,  //市
        "countyGeoId": district, //区，县
        "postalAddress": address, //详细地址
        "description":description, //需求描述：订单管理OMS,仓储管理WMS,分销管理
    };
    return postData('wbCreateCustomerRequest',jsonData)

}

//微信登录
export function wxLogin(code)
{
    let jsonData = {
        "code": code  //微信登录code
    };

    return postDataWithoutToken('wbWxLogin',jsonData)
}


//wbUpdateUserSetting，更新用户设置
export function wbUpdateUserSetting(dataSet)
{
    let jsonData=dataSet;
    // let jsonData = {
    //     "userLoginId": "qd2023-07-1003",  //用户名
    //     "firstName": "qd2023-07-1003", //姓名
    //     "phoneNumber": "13800001166", //手机号码
    //     "currentPassword": "",      //当前密码
    //     "newPassword": "",          //新密码
    //     "newPasswordVerify": "",    //新验证密码
    //     "entPartyName": "皇家假日酒店2307",  //公司
    //     "entProvinceGeoId": "CN-SH", //省
    //     "entCityGeoId": "310100",  //市
    //     "entCountyGeoId": "310115", //区，县
    //     "entPostalAddress": "成山路2000号" //公司地址
    // };

    return postData('wbUpdateUserSetting',jsonData)

}
