import React from 'react';
import {
  Row,
  Col,
  Button,
  Icon
} from 'antd';

export default class DetailJoin extends React.Component {
	render(){
		return(
			<div className="detail">
				<Row type="flex" justify="center">
					<Col span={14} >
						<div><span ><Button className="btn-back"> <Icon type="left" />返回</Button></span>客户经理</div>

						<p className="p-inline">工作地点：上海 </p> <p className="p-inline">招聘数量：2</p>	 <br/><br/>
						<p className="p-inline">职位类别：销售 </p> <p className="p-inline">招聘渠道：社会招聘</p>	<br/><br/><br/>
						<p>职位描述:</p>
						<p>1、 负责QDiCloud云平台与服务软件解决方案的销售工作。</p>
						<p>2、 负责制定客户经营计划，积极有效推进客户经营，承担销售指标、完成销售业绩目标。</p>
						<p>3、 负责QDiCloud云平台增值服务的推广和销售工作，包括销售文件准备及商务报价等工作。</p><br/><br/><br/>

						<p>任职要求：</p>
						<p>1、 有照明、智能家居，家电，商用设备等行业资源和行业销售经验优先考虑。</p>
						<p>2、 关注物联网发展方向、对物联网相关技术有一定了解。</p>
						<p>3、 具备良好的沟通、表达、商务谈判能力。</p>
						<p>4、 积极主动，喜欢具有挑战性工作，对工作有信心和激情。</p>
						<p>5、 具有团对合作意识，具备努力开拓市场的精神，有个人奋斗目标和信心。</p><br/><br/>

						<p>薪酬：10K-24K</p> <br/><br/>

						<p>工作地点：上海市浦东新区杨高南路2875号1号楼3001室</p> <br/><br/>
						<p>有意者请发简历至hr@qdicloud.com，邮件标题为“姓名+应聘职位+招聘信息来源”。</p>
					</Col>
				</Row>

			</div>
		);
	};
}