import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {Avatar, Button, Col, Menu, Row, Icon} from "antd";
import "./style.css";
import logoqdcloud from "../../statics/index/logo_qdcloud.svg";
import {fetchLaunchedSolutionList} from '../../actions/fetchDataAction';
import mobxstore from "../../mobxStore/store";

class Head extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: []
        }
    }

    componentDidMount() {
        const header=document.querySelector('.ant-layout-header')
        // 根据顶部距离调整head的不透明度
        window.addEventListener('scroll', () => {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            let opacity=scrollTop/1000+0.25
            header.style.backgroundColor=`rgba(0,0,0,${opacity})`
        });


        //获取已上线方案列表
        fetchLaunchedSolutionList().then((res) => {
            if (res && res.data && res.data.data.itemList) {
                // sessionStorage.setItem("launchedSolutionList", JSON.stringify(res.data.data.itemList))
                // this.setState({launchedSolutionList:res.data.data.itemList})
                // const menu=sessionStorage.getItem('launchedSolutionList')
                if(res.data.data.itemList&&res.data.data.itemList[0].sequenceNum){
                    // 按照 sequenceNum 属性进行升序排序
                   let menu= res.data.data.itemList.sort((a, b) => a.sequenceNum - b.sequenceNum);
                    this.setState({menu})
                    mobxstore.setMenuList(menu)
                }else {
                    this.setState({menu: res.data.data.itemList})
                }

            }


        })
    }

    render() {
        const {SubMenu} = Menu;
        const {menu} = this.state

        let isLogged = window.sessionStorage.getItem('isLogged')
        return (
            <div>
                <Row>
                    <Col span={24}>
                        <div className="logo">
                            <Link to="/index">
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img src={logoqdcloud} alt="logo-qdcloud" style={{height: '40px'}}/>
                                    <span className='header-title'>起典云</span>
                                </div>
                            </Link>
                        </div>
                        <Menu
                            theme="light"
                            mode="horizontal"
                            defaultSelectedKeys={[this.props.history.location.pathname]}
                            selectedKeys={[this.props.history.location.pathname]}
                            style={{
                                lineHeight: "80px",
                                float: 'right',
                                fontSize: '0.2rem',
                                fontWeight: 600,
                                position: 'relative'
                            }}
                        >
                            <Menu.Item key="/index">
                                <Link to="/index">首页</Link>
                            </Menu.Item>
                            <Menu.Item key="/product">
                                <Link to="/product">产品</Link>
                            </Menu.Item>
                            {/*<Menu.Item key="/solution">*/}
                            {/*  <Link to="/solution">解决方案</Link>*/}
                            {/*</Menu.Item>*/}
                            <SubMenu
                                title={
                                    <span className="submenu-title-wrapper">
                         解决方案
                    </span>
                                }
                            >

                                {menu && menu.map((route) => (
                                    <Menu.Item key={route.routeUrl}>
                                        <Link to={route.routeUrl}>{route.solutionName}</Link>
                                    </Menu.Item>
                                ))}


                                {/*<Menu.Item key="/solution_point_reward">*/}
                                {/*    <Link to="/solution_point_reward">积分商城系统</Link>*/}
                                {/*</Menu.Item>*/}

                                {/*<Menu.Item key="/solution_new_retail">*/}
                                {/*    <Link to="/solution_new_retail">新零售数字化门店解决方案</Link>*/}
                                {/*</Menu.Item>*/}

                                {/*<Menu.Item key="/solution_wms">*/}
                                {/*    <Link to="/solution_wms">仓储管理解决方案</Link>*/}
                                {/*</Menu.Item>*/}

                                {/*<Menu.Item key="/solution_tms">*/}
                                {/*    <Link to="/solution_tms">运输管理TMS解决方案</Link>*/}
                                {/*</Menu.Item>*/}
                            </SubMenu>

                            <Menu.Item key="/customer">
                                <Link to="/customer">成功案例</Link>
                            </Menu.Item>
                            {/* <Menu.Item key="3">社 区</Menu.Item> */}
                            <Menu.Item key="/project">
                                <Link to="/project">免费试用</Link>
                            </Menu.Item>

                            <Menu.Item key="/about">
                                <Link to="/about">关于我们</Link>
                            </Menu.Item>
                            {/*<Menu.Item key="/access">*/}
                            {/*  <Link to="/access">接入指南</Link>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item key="/myproject">{isLogged?<Link to="/myproject">我的项目</Link>:<Link to="/login">我的项目</Link>}</Menu.Item>*/}
                            {/*{sessionStorage.getItem('roleTypeId') ?*/}
                            {/*    <Menu.Item key="/projectManager">*/}
                            {/*        <Link to="/projectManager">项目管理</Link>*/}
                            {/*    </Menu.Item> : ''}*/}
                            <Menu.Item key={isLogged ? "/myaccount" : "/login"} className="btn"
                                       style={{marginLeft: '11.5rem', marginRight: isLogged ? '1rem' : '1.5rem'}}>
                                {isLogged ?
                                    <Link style={{textDecoration: 'none'}} to={{pathname: "/myaccount", search: "id="}}>
                                        <Avatar size="large" icon="user"/>
                                    </Link> : (
                                        <>
                                            <Link to="/login">登录</Link>
                                        </>
                                    )}
                            </Menu.Item>

                            {isLogged ? '' : <Menu.Item style={{marginRight: '1.5rem'}}>|</Menu.Item>}
                            {isLogged ? (
                                <SubMenu
                                    title={
                                        <span className="submenu-title-wrapper">
                                ▼
                                </span>
                                    }
                                >
                                    <Menu.Item key="/register" className="btn">
                                        <Link to="/" onClick={() => {
                                            sessionStorage.removeItem("isLogged")
                                            sessionStorage.removeItem('token')
                                            sessionStorage.removeItem('roleTypeId')
                                        }
                                        }>退出</Link>


                                    </Menu.Item>


                                    {sessionStorage.getItem('roleTypeId') ? (
                                        <Menu.Item key="/projectManage" className="btn">
                                        <span onClick={() => {
                                            window.open("http://122.112.170.70:6002/"); //跳转到后台
                                        }}>后台管理</span>
                                        </Menu.Item>
                                    ) : ''}


                                </SubMenu>
                            ) : <Menu.Item key="/register" className="btn"><Link to="/register">注册</Link></Menu.Item>}

                        </Menu>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(Head);
