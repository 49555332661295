import React from 'react';
import {
    Row,
    Col
  } from 'antd';
import './style.css';
import struct1 from '../../statics/accessGuide/structure-1.png';
import struct2 from '../../statics/accessGuide/structure-2.png';

export default class AccessGuide extends React.Component {
    render(){
        return(
            <div className="access">
                <Row type="flex" justify="center">
                    <Col span={16}>
                    <h1>平台概述</h1>
                    <p>QDiCloud平台是公司经过多年行业内的耕耘及对物联网行业的深刻理解，而推出的面向个人、企业开发者的一站式智能硬件开发及云服务平台。平台提供了从应用开发、云端开发、运营管理、数据服务等覆盖智能硬件接入到运营管理服务的能力。</p>
                    <p>QDiCloud平台为合作伙伴和开发者提供了相关的开发套件，让接入更加轻松、简单、快捷。</p>
                    <p>为简化企业的物联网设备接入和应用开发，QDiCloud提供了设备端SDK、Android和iOS等应用端系列开发套件，您可以从以下链接获得相关信息和工具。</p>
                    </Col>
                </Row>  

                <div className="access-platform">
                    <Row type="flex" justify="center">
                        <Col span={16}>
                            <h1>平台概述</h1>
                            <Row type="flex" justify="center">
                                <Col span={11}>
                                    <img src={struct1} alt="img1"/>
                                </Col>
                            </Row>
                            <Row className="access-img" type="flex" justify="center">
                                <Col span={16}>
                                    <img src={struct2} alt="img2"/>
                                </Col>
                            </Row>                        
                        </Col>
                    </Row>                
                </div>  
                





                 <Row type="flex" justify="center">
                    <Col span={16}>
                    <h1>云优势</h1>
                    <p>2) 快速接入</p>
                    <p>3) 专业服务</p>
                    <p>5) 安全性</p>
                    <p>6) 多层次云端数据服务</p>
                    <p>7) 开放性</p>
                    </Col>
                </Row>            
            </div>
        );
    }
}