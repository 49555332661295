import React from 'react';
import {
  Row,
  Col
} from 'antd';
import './style.css';
import banner from '../../statics/about/banner_1.png';
import company from '../../statics/about/img_company.png';

export default class AboutUs extends React.Component {
	render(){
		return(
			<div className="about">
				<Row type="flex" justify="center">
					<Col span={24} >
						<div className="banner" style={{position:'relative'}}>
							<img src={banner} alt="banner about us"/>
							<div className='about-banner-title banner-font'>关于起典云</div>
						</div>
					</Col>
				</Row>
				

				<Row type="flex" justify="center">
					<Col span={16} >
						<div style={{marginTop:'2rem'}}>
							上海起典软件技术有限公司（起典云QDCloud）是一家专注于电子商务和智慧物流领域ERP平台，为企业提供全面、丰富的电子商务及智慧物流的整体解决方案。
						</div>

						<div style={{marginTop:'2rem'}}>起典云主要解决方案有起典OMS订单管理系统、起典电商小程序、起典WMS仓储管理系统及起典TMS运输管理系统等。其中起典OMS订单管理系统服务企业超过50家，起典云平台已广泛应用于零售百货、制造业、科技，医药食品等行业。起典云深耕电商行业，为不同行业定制全方位解决方案，助力企业构建数字商业闭环。</div>

						<div style={{marginTop:'2rem'}}>起典云成立于2016年，创始人兼CEO沈坚拥有超过二十年传统软件及电商ERP的研发和实施部署经验。起典云创建之初，以电商ERP为切入点，凭借出色的产品和服务，快速获得市场的肯定。随着客户需求的不断变化，如今起典云已经发展成为以SaaS ERP为核心，集多种商家服务（包含订单管理、仓储管理、运输管理等）为一体的SaaS协同平台。</div>

						<div style={{marginTop:'2rem'}}> 经过多年的奋斗与努力，公司员工从2016年成立之初的2人增加到现在一百多人。起典云已在全国设立了多个线下服务网点，服务范围覆盖超过几十个城镇，为客户提供迅速、专业、真挚的服务。</div><br/>


					</Col>


				</Row>

				<Row type='flex' justify='center' style={{paddingTop:'2rem'}}>
					<Col span={16} >
						<img src={company} style={{width:'100%'}}/>
					</Col>
				</Row>

			</div>
		);
	}
}