import React, { Component } from 'react';
import {
	Row,
	Col,
	Button,
	Form,
	Input, Icon,
} from 'antd';
import './style.css';
import {Link} from "react-router-dom";

const FormItem = Form.Item;

class UpdatePwd extends Component {
	validateInput = (_, value) => {
		// 正则表达式校验规则
		const regExp = /^[a-zA-Z0-9]{6,21}$/;

		if (value && !regExp.test(value)) {
			return Promise.reject('请输入6到21位的数字或字母');
		}

		return Promise.resolve();
	};
	render(){
		const { getFieldDecorator } = this.props.form;
		return(
			<div className="forget">
				<Row type="flex" justify="center">
					<Col span={6} >

						<div style={{display:'flex',justifyContent:'space-between',paddingTop:'8rem',paddingBottom:'1rem'}}>
							<div style={{fontWeight: 'bolder', fontSize: '1.5rem'}}>忘记密码</div>
							<div style={{display: 'flex', alignItems: 'center'}}>
								<Link to="/login">
									<span className='register-login'>登录</span>
									<span style={{color: '#20aee5'}}><Icon type="caret-right"/></span></Link>
							</div>
						</div>

						<Form layout='vertical'>
							<FormItem
								name="phone"
								label="手机号"
							>
								<Input className="input"  placeholder="请输入手机号码时" />
							</FormItem>

							<FormItem
								label="验证码"
							>
								<div style={{position:'relative'}}>
									<Input
										maxLength={15}
										className="input"
										placeholder="请输入验证码"
									/>
								</div>

								<div className='get-verification-code'>获取验证码</div>
							</FormItem>

							<FormItem
								label="设置密码："
							>
								{getFieldDecorator('password', {
									rules: [
										{
											validator: this.validateInput,
										},
									],
								})(
									<Input.Password
										maxLength={21}
										className="input"
										rules={[
											{
												validator: this.validateInput,
											},
										]}
										placeholder="6到21位数字或字母"
										// onChange={value => this.handleChange('password', value)}
									/>
								)}
							</FormItem>

							<FormItem
								label="再次输入密码"
							>
								<Input.Password
									className="input"
									placeholder="再次输入密码"
									// onChange={value => this.handleChange('pwdConfirm', value)}
								/>
							</FormItem>


							<FormItem >
								<Button className="btn-login" type="primary">提交</Button>
							</FormItem>

						</Form>
					</Col>
				</Row>
			</div>
		);
	}
}

export default Form.create()(UpdatePwd)