import React, {Component} from 'react';
import {
    Row,
    Col, notification
} from 'antd';
import indexImg from '../../statics/index_2/banner_01.png';
import banner2 from '../../statics/index_2/banner_02.png';
import productImage1 from '../../statics/index_2/product_01.png'
import PaddingWrapper from '../../component/PaddingComponent'
import advantage1 from '../../statics/index_2/draw_01.svg';
import advantage2 from '../../statics/index_2/draw_02.svg';
import advantage3 from '../../statics/index_2/draw_03.svg';
import advantage4 from '../../statics/index_2/draw_04.svg';
import classic1 from '../../statics/index_2/img_case_01.png';
import classic2 from '../../statics/index_2/img_case_02.png';
import classic3 from '../../statics/index_2/img_case_03.png';
import classic4 from '../../statics/index_2/img_case_04.png';
import classicA from '../../statics/index_2/ico_QRcode.svg';
import classicB from '../../statics/index_2/ico_QRcode.svg';
import classicC from '../../statics/index_2/ico_QRcode.svg';
import classicD from '../../statics/index_2/ico_QRcode.svg';
import speedIco from '../../statics/index_2/ico_speed.svg';
import lampIco from '../../statics/index_2/ico_lamp.svg';
import mouseBanner from '../../statics/index_2/g_sybanner_btnx.png';
import CarouselWrapper from "../../component/CarouselComponent";
import CardWrapper from "../../component/CardComponent";
import cardEnum from '../../component/enum/cardEnum';
import paddingEnum from '../../component/enum/paddingEnums';
import './style.css';
import ShowAnimate from "../../common/showAnimate";
import bgGrey from '../../statics/index_2/bg-grey-01.jpg';
import cardPic1 from "../../statics/solution2/draw_01.svg";
import ErrorBoundary from "../../common/errorBoundary";
import buttonIco from "../../statics/index_2/button-ico.svg";
import blueButtonIco from "../../statics/index_2/ico_msg_blue.svg";
import {fetchDataWithoutToken} from '../../common/axios/request';
import {withRouter} from 'react-router-dom';
import ButtonWrapper from "../../component/ButttonComponent";
import ButtonType from "../../component/enum/buttonEnum";
import _ from 'lodash';
import config from "../../config";
import {fetchLaunchedCaseList} from '../../actions/fetchDataAction';
import {wxLogin} from '../../actions/postAction';
import createHistory from 'history/createBrowserHistory';
import {getTypeList} from "../../config/api";
import ScrollView from "../../component/ScrollViewComponent";
import scrollViewPic0 from "../../statics/scrollView/1_01.png";
import scrollViewPic1 from "../../statics/scrollView/1_02.png";
import scrollViewPic2 from "../../statics/scrollView/1_03.png";
import scrollViewPic3 from "../../statics/scrollView/1_06.png";
import scrollViewPic4 from "../../statics/scrollView/2_06.png";
import scrollViewPic5 from "../../statics/scrollView/2_02.png";
import scrollViewPic6 from "../../statics/scrollView/2_04.png";
import scrollViewPic7 from "../../statics/scrollView/1_08.png";
import scrollViewPic8 from "../../statics/scrollView/1_09.png";



const history = createHistory();


class Index extends Component {
    constructor(props) {
        super(props);
        this.getAccessToken() //微信登录校验
        const cardObj = [
            {
                title: '全渠道订单同步',
                type: cardEnum.IMAGE_TITLE_DETAIL,
                images: advantage1,
                imageStyle: {width: '100%', height: '50%',padding:'16px'},
                detail: '全渠道电商平台免费对接\n' +
                    '商品、订单实时同步',
                boxStyle: {backgroundColor: 'white', paddingTop: '16px', borderRadius: '8px'}
            },
            {
                title: '全渠道一盘货',
                type: cardEnum.IMAGE_TITLE_DETAIL,
                images: advantage2,
                imageStyle: {width: '100%', height: '50%',padding:'16px'},
                detail: '库存管理与共享，降低库存成本，\n' +
                    '提升库存周转率，提高发货时效',
                boxStyle: {backgroundColor: 'white', paddingTop: '16px', borderRadius: '8px'}
            },
            {
                title: '无纸化作业',
                type: cardEnum.IMAGE_TITLE_DETAIL,
                images: advantage3,
                imageStyle: {width: '100%', height: '50%',padding:'16px'},
                detail: '拣货、验货、称重、发货，\n' +
                    '全流程无纸化扫码作业',
                boxStyle: {backgroundColor: 'white', paddingTop: '16px', borderRadius: '8px'}
            },
            {
                title: '智能审单',
                type: cardEnum.IMAGE_TITLE_DETAIL,
                images: advantage4,
                imageStyle: {width: '100%', height: '50%',padding:'16px'},
                detail: '自动匹配商品、仓库快递、赠品等，\n' +
                    '自动合单，智能审单，精准高效',
                boxStyle: {backgroundColor: 'white', paddingTop: '16px', borderRadius: '8px'}
            },
        ]

        // const cardObj2 = [
        //     {title: '供享生活', type: cardEnum.IMAGE_TITLE_IMAGE, images: classic1, images2: classicA, tag: '#百货商超'},
        //     {title: '照片管理系统', type: cardEnum.IMAGE_TITLE_IMAGE, images: classic2, images2: classicB, tag: '#其他'},
        //     {title: '艺泰乐器', type: cardEnum.IMAGE_TITLE_IMAGE, images: classic3, images2: classicC, tag: '#其他'},
        //     {title: '麦扣扣', type: cardEnum.IMAGE_TITLE_IMAGE, images: classic4, images2: classicD, tag: '#家居百货'},
        // ]
        const cardObj2 = []
        const titleArr = ['功能多', '系统块', '服务好', '成本省'];
        const detailArr = ["支持免费试用，不限使用人数。支持跨平台多店铺，库存统一管理。智能审单，快速配货，订单管理。",
            "自动快速下载订单，每年系统升级410次，日均发送包裹数超过70000单。全网订单总量突破8.52亿。",
            "全国超过100个线下服务网点,服务范围覆盖超过500个城镇，为客户提供及时、周到和专业的服务。",
            "仓库作业效率提升，降低人工成本;发货准确率提升，降低错发漏发成本;信息化程度提升，降低耗材成本。"
        ];
        const productImageArr = [productImage1, productImage1, productImage1, productImage1] //核心产品图片
        const qdCloudAdvantage = [advantage1, advantage2, advantage3, advantage4]//起点云优势图片
        const classicCase1 = [classic1, classic2, classic3, classic4]//经典案例图片上
        const classicCase2 = [classicA, classicB, classicC, classicD]//经典案例图片下

        const scrollViewArr = [scrollViewPic0,scrollViewPic1, scrollViewPic2, scrollViewPic3, scrollViewPic4, scrollViewPic5, scrollViewPic6, scrollViewPic7]

        this.state = {
            scrollViewArr,
            cardObj,
            cardObj2,
            detailArr,
            titleArr,
            productImageArr,
            qdCloudAdvantage,
            classicCase1,
            classicCase2,
            bannerHeight:0,
        }
    }

    fetchLaunchedProductList = () => {
        let sortField;
        let searchList;
        let projectCategoryId = null;
        sortField = "productId DESC"; //ASC - 升序， DESC -降序
        searchList = {
            "projectCategoryId": projectCategoryId
        };
        let jsonData = {
            "viewIndex": 1,  //start from 1
            "viewSize": 20,
            "sortField": sortField,
            "searchList": searchList
        };

        fetchDataWithoutToken('wbGetLaunchedProductList', JSON.stringify(jsonData))
            .then((res) => {
                const itemList = res.data.data.itemList;
                this.setState({productItemList: itemList})

            })

    }

    fetchCaseList = (searchField, searchValue) => {
        fetchLaunchedCaseList(searchField, searchValue).then((res) => {
            let result = res.data.data.itemList;
            let cardObj = _.map(result, ({caseName, caseImageUrl, qrcodeImageUrl, tagList, description,customerLogoUrl}) => ({
                title: caseName,
                type: cardEnum.IMAGE_ICON_DETAIL,
                images: config.SERVER_URL + caseImageUrl,
                images2: config.SERVER_URL + customerLogoUrl
                ,
                tag: tagList,
                description,
            }));

            this.setState({cardObj2: cardObj})

        })
            .catch((e) => {
                console.log('获取已上线案例失败', e)
            })

    }


    fetchTypeList = (fieldName) => {
        getTypeList({fieldName}).then((res) => {
            if (res.data && res.data.data) {
                this.setState({cardObjMenu: res.data.data.values})

                this.fetchCaseList('industryCategoryId', res.data.data.values[0].key);//获取已上线案例
            }
        })
    }

    openNotificationWithIcon = (type, desc) => {
        notification[type]({
            message: '提示',
            description: desc,
        });
    };


    componentDidMount() {
        //获取bannerImg的高度
        setTimeout(()=>{
            let bannerImg = document.querySelector('.img-banner')
            if(bannerImg.clientHeight){
                this.setState({bannerHeight:bannerImg.clientHeight})
            }

        },100)


        this.fetchLaunchedProductList();//获取线上产品列表
        this.fetchCaseList();//获取已上线案例
        // this.fetchTypeList('industryCategoryId')  //获取成功案例菜单列表
    }

    getAccessToken() {
        //获取地址栏参数字符串
        let queryString = window.location.href;
        let paramString = queryString && queryString.split("?")[1]
        let paramArr = paramString && paramString.split("&")
        let paramsObj = {}
        paramArr && paramArr.map((item) => {
            let param = item.split("=")
            let obj = {[param[0]]: param[1]}
            paramsObj = {...paramsObj, ...obj}
        })
        let code = paramsObj.code
        if (code) {
            wxLogin(code)
                .then((res) => {
                    if (res.data.statusCode == 200) {
                        sessionStorage.setItem("token", 'Bearer ' + res.data.data.token);
                        sessionStorage.setItem("partyId", res.data.data.partyId);
                        sessionStorage.setItem('isLogged', true);
                        if (res.data.roleTypeId === 'ROLE_SYS_ADMIN') {
                            sessionStorage.setItem('roleTypeId', res.data.data.roleTypeId)
                            // window.open("http://122.112.170.70:6002/"); //管理员登录之后跳转到后台
                        }
                        history.replace('/');
                        history.go();
                        // this.openNotificationWithIcon('success', '登录成功')
                    } else {
                        this.openNotificationWithIcon('error', res.data.errorMessage)
                    }
                })
                .catch((e) => {
                    this.openNotificationWithIcon('error', e)
                })
        } else {
            return;
        }


    }

    clickedButton = () => {
        this.props.history.push('/project'); // 跳转到指定路径
    }

    render() {
        const {cardObj, cardObj2, productImageArr, productItemList, cardObjMenu, scrollViewArr,bannerHeight} = this.state
        return (
            <div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    {/*<Col span={24} className="banner">*/}
                        <img src={indexImg} style={{position: 'relative'}} alt="index-img" className="img-banner"/>
                        <div className='index-font-style index-position-center'>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                {/*<div>起典云，电商ERP首选</div>*/}
                                <div className='banner-font'>起典云</div>
                                <div className='banner-font'>电商ERP首选</div>
                                <ButtonWrapper
                                    buttonStyle={{marginTop: '7rem'}}
                                    type={ButtonType.LI_JI_ZI_XUN}
                                >立即咨询</ButtonWrapper>
                            </div>
                        </div>

                        {/*<div className='mouse-style'>*/}
                        {/*    <img src={mouseBanner} style={{width: '35px', height: 'auto'}}/>*/}
                        {/*    <div className='scroll'><span>SCROLL</span></div>*/}
                        {/*</div>*/}

                    {/*</Col>*/}
                </div>


                {/*<div style={{height: '280px',backgroundColor:'#f3f3f3'}}></div>*/}
                {
                    productItemList ? (
                        <div>
                            <ShowAnimate>
                                <CarouselWrapper
                                    title='核心产品'
                                    titlePosition='left'
                                    // images={productImageArr}
                                    productItemList={productItemList}
                                    size='large'
                                    wrapperStyle={{backgroundColor:'#f3f3f3',height:window.screen.width*543/1511}}
                                />
                            </ShowAnimate>
                        </div>

                    ) : ''

                }

                {/*起典云优势*/}
                <div >
                    <ShowAnimate>
                        <ErrorBoundary>
                            <CardWrapper
                                cardObj={cardObj}
                                title='起典云优势'
                                titlePosition='left'
                                cardStyle={{backgroundColor:"#f3f3f3"}}
                            />
                        </ErrorBoundary>
                    </ShowAnimate>
                </div>


                {/*典型案例*/}
                <ShowAnimate>
                    <ErrorBoundary>
                        <CardWrapper
                            cardObj={cardObj2}
                            cardObjMenu={cardObjMenu}
                            fetchCaseList={this.fetchCaseList}
                            title='典型案例'
                            cardStyle={{backgroundColor:'white'}}
                            maxLength={12}
                        />
                    </ErrorBoundary>
                </ShowAnimate>


                {/*合作客户*/}
                <ShowAnimate>
                    <ErrorBoundary>
                        <CardWrapper
                            title='合作客户'
                            titlePosition='left'
                            cardStyle={{backgroundColor:'#f3f3f3'}}
                        >
                            <ScrollView scrollViewArr={scrollViewArr}></ScrollView>
                        </CardWrapper>
                    </ErrorBoundary>
                </ShowAnimate>

                <div style={{width:'100%',height:'100px',backgroundColor:'#f3f3f3'}}></div>
                {/*<Solution/>*/}

                {/*优秀案例*/}
                {/*<Demo/>*/}

                {/*合作客户*/}

                {/*<Published/>*/}
                <div style={{position: 'relative'}}>
                    <img src={banner2} style={{width: '100%',height:'420px'}}/>
                    <div style={{
                        position: 'absolute',
                        zIndex: 100,
                        top: '30%',
                        left: '50%',
                        transform: 'translate(-50%,0)',
                        color: 'black',
                        fontSize: '30px',
                        fontFamily: '黑体',
                        fontWeight: 600
                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <div className='ERP-title' style={{letterSpacing: '3px'}}>起典云电商仓储ERP,马上出发!</div>
                            <div className='ERP-desc' style={{fontWeight: 400, fontSize: '24px', marginTop: '30px'}}>为您提供专业的解决方案</div>
                            <ButtonWrapper
                                buttonStyle={{marginTop: '1rem'}}
                                type={ButtonType.LI_JI_ZI_XUN}
                            >立即咨询</ButtonWrapper>
                            {/*<div className='white-button' style={{marginTop: '3rem'}} onClick={this.clickedButton}>*/}
                            {/*    <img src={blueButtonIco} style={{width: '26px', paddingRight: '8px'}}/>*/}
                            {/*    <span>立即咨询</span>*/}
                            {/*</div>*/}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Index)