import React from 'react';
import {Col, Row} from "antd";
import banner from "../../statics/solution2/banner_1.png";
import style from './style.module.css';
import icon1 from '../../statics/solution2/ico_link.svg';
import icon2 from '../../statics/solution2/ico_star.svg';
import icon3 from '../../statics/solution2/ico_split.svg';
import icon4 from '../../statics/solution2/ico_newuser.svg';
import icon5 from '../../statics/solution2/user_shoppingcart.svg';

import cardPic1 from '../../statics/solution2/draw_01.svg';
import cardPic2 from '../../statics/solution2/draw_02.svg';
import cardPic3 from '../../statics/solution2/draw_03.svg';
import cardPic4 from '../../statics/solution2/draw_04.svg';

import pointBg from '../../statics/solution2/points_bg.png';
import point2 from "../../statics/solution2/points2_img.svg";
import scenario from '../../statics/solution2/img_scenario_1.png';
import CardWrapper from "../../component/CardComponent";
import cardEnum from "../../component/enum/cardEnum";
import ShowAnimate from "../../common/showAnimate";
import buttonIco from "../../statics/solution/ico_fly_white.svg";
import buttonIco2 from "../../statics/index_2/button-ico.svg";
import ButtonWrapper from "../../component/ButttonComponent";
import ButtonType from "../../component/enum/buttonEnum";

const SolutionHead2 = () => {
    const iconArr = [
        {icon: icon1, title: '粘性'},
        {icon: icon2, title: '活跃'},
        {icon: icon3, title: '裂变'},
        {icon: icon4, title: '拉新'},
        {icon: icon5, title: '复购'}
    ]
    const cardObj = [
        {
            title: '增强用户粘性', type: cardEnum.IMAGE_TITLE_DETAIL, titleStyle: {
                textAlign: 'center'
            }, images: cardPic1, detail: '引导用户获取积分,利用积分体系黏住客户',boxStyle:{backgroundColor:'#f5f5f5'},
            imageStyle:{width:'100%',height:'50%'}

},
        {
            title: '提高用户活跃度', type: cardEnum.IMAGE_TITLE_DETAIL, titleStyle: {
                textAlign: 'center'
            }, images: cardPic2, detail: '积分制度刺激用户行为,让用户积极参与平台互动',boxStyle:{backgroundColor:'#f5f5f5'},
            imageStyle:{width:'100%',height:'50%'}
        },
        {
            title: '降低运营成本',
            type: cardEnum.IMAGE_TITLE_DETAIL,
            titleStyle: {
                textAlign: 'center'
            },
            images: cardPic3,
            detail: '积分运营是目前的市场方向之一,积分体系是成本较低且灵活通用的运营手段',boxStyle:{backgroundColor:'#f5f5f5'},
            imageStyle:{width:'100%',height:'50%'}
        },
        {
            title: '增加商品销量', type: cardEnum.IMAGE_TITLE_DETAIL, titleStyle: {
                textAlign: 'center'
            }, images: cardPic4, detail: '积分搭售商品，驱动用户购买欲,提升商品销量',boxStyle:{backgroundColor:'#f5f5f5'},
            imageStyle:{width:'100%',height:'50%'}
        },
    ]

    return (
        <div className="solutionHead">
            <Row type="flex" justify="center">
                <Col span={24}>
                    <div className="banner-join" style={{position: 'relative'}}>
                        <img src={banner} alt="banner solution"/>
                        <div className={style.fontTitle}>
                            <div className='banner-font'>积分商城系统解决方案</div>
                            <div className='banner-font-middle' style={{marginTop:'1rem',fontWeight:500,fontSize:'22px'}}>60秒搭建会员积分商城，积分营销，裂变积分</div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '66%',
                                marginLeft: '17%',
                                marginTop:'4rem'
                            }}>
                                {iconArr.length > 0 && iconArr.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <img src={item.icon} style={{width: '1.5rem', height: '1.5rem'}}/>
                                            <div style={{fontSize: '1.1rem'}}>{item.title}</div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div style={{display:'flex',justifyContent:'center'}}>
                                <ButtonWrapper
                                    buttonStyle={{marginTop: '4rem'}}
                                    type={ButtonType.MIAN_FEI_SHI_YONG_WHITE}
                                >免费试用</ButtonWrapper>
                                <div style={{width:'15px'}}></div>

                                <div className='banner-button' style={{marginTop: '4rem',backgroundColor:'transparent',borderColor:'white'}}>
                                    <img src={buttonIco2} style={{width: '26px', paddingRight: '8px'}}/>
                                    <span>在线咨询</span>
                                </div>
                            </div>

                            {/*</div>*/}
                        </div>

                    </div>
                </Col>
            </Row>
            <ShowAnimate>
                <CardWrapper
                    title="积分商城的优势"
                    detail="积分商城将用户在业务中获取的积分有价化，激励和引导用户的购买行为，从而达到引流和提高商品销量的目的。"
                    cardObj={cardObj}
                    cardStyle={{paddingBottom:'20px'}}
                />
            </ShowAnimate>

            <ShowAnimate>
                <CardWrapper
                    title="数10种积分玩法"
                    detail="帮助企业解决在客户回馈、会员促销等场景下的积分营销难题，每日签到、分享送积分、关注送积分、购物送积分、..."
                    imgSrc={point2}
                    cardStyle={{backgroundImage: `url(${pointBg})`, backgroundSize: 'cover', color: 'white'}}
                />
            </ShowAnimate>

            <div style={{position: 'relative'}}>
                <img src={scenario} style={{ width: '100%'}}/>
                <div style={{position: 'absolute', top: '25vh', right: '15%', textAlign: 'right'}}>
                    <ShowAnimate>
                        <div style={{fontSize: '44px', color: 'white', fontWeight: 'bolder'}}>我们的积分应用场景</div>
                        <div style={{color: 'white', marginTop: '1.5rem',fontSize:'16px'}}>多种积分应用场景，有利于稳定用户群体，提升用户的消费频次</div>
                        <div style={{
                            color: 'white',
                            marginTop: '1.5rem',
                            width: '100px',
                            borderTop: 'solid 1px',
                            float: 'right'
                        }}></div>
                        <div style={{clear: 'both'}}></div>
                        <div style={{color: 'white', fontWeight: 'bolder', marginTop: '1rem',fontSize:'16px'}}>1、线上获取积分</div>
                    </ShowAnimate>

                </div>
            </div>
        </div>
    );
}

export default SolutionHead2;
