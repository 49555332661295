import React, {useEffect, useState} from 'react';
import {Col, Row} from "antd";
import banner from "../../statics/solution4/banner_1.png";
import style from "./style.module.css";
import TabsComponent from "../../component/TabsComponent";
import placeholder from "../../statics/solution4/img_placeholder.png";
import feature from "../../statics/solution4/feature_draw_1.png";
import feature2 from "../../statics/solution4/feature_draw_2.png";
import feature3 from "../../statics/solution4/feature_draw_3.png";
import CardWrapper from "../../component/CardComponent";
import problemBg from "../../statics/solution4/problem_bg.png";
import ShowAnimate from "../../common/showAnimate";
import cardEnum from "../../component/enum/cardEnum";
import ico1 from "../../statics/solution4/problem_ico_efficiency.svg";
import ico2 from "../../statics/solution4/problem_ico_cost.svg";
import ico3 from "../../statics/solution4/problem_ico_management.svg";
import ico4 from "../../statics/solution4/problem_ico_quality.svg";
import arrow from "../../statics/solution4/problem_arrow.svg";
import solutionBg from "../../statics/solution4/solution_bg.png";
import increase from '../../statics/solution4/solution_ico_arrow_increase.svg';
import decrease from '../../statics/solution4/solution_ico_arrow_decrease.svg';
import case1 from '../../statics/solution4/case_1.png';
import case2 from '../../statics/solution4/case_2.png';
import case3 from '../../statics/solution4/case_3.png';
import case4 from '../../statics/solution4/case_4.png';
import buttonIco from "../../statics/index_2/button-ico.svg";
import ButtonType from "../../component/enum/buttonEnum";
import ButtonWrapper from "../../component/ButttonComponent";
import bannerProduct from "../../statics/solution4/banner_1_product.png";
import ReasonBg from "../../statics/solution/img_reason_bg.png";
import logo from "../../statics/solution/Logo-gxsj.png";
import ImageCase01 from "../../statics/solution/img_case_01.png";
import mobxstore from "../../mobxStore/store";
import {wbGetLaunchedCaseList} from "../../config/api";
import _ from "lodash";
import config from "../../config";

const SolutionHead4 = () => {
    const displayWidth = 1200
    const tabsObjArr = [{
        title: '入库管理',
        image: feature,
        style: {width: '86%', height: "auto",paddingTop:'30px',paddingBottom:'74px',marginLeft:'7%'}
    }, {
        title: '拣配出库', image: feature2,
        style: {width: '86%', height: "auto",paddingTop:'30px',paddingBottom:'74px',marginLeft:'7%'}
    }, {
        title: '仓内管理', image: feature3,
        style: {width: '86%', height: "auto",paddingTop:'30px',paddingBottom:'74px',marginLeft:'7%'}
    }]

    const cardObj2_DEMO = [
        {
            images: ImageCase01,
            images2: logo,
            description: '好的界面设计并不始于图片，而是始于对人的理解，比如人们喜欢什么，为什么人们会试用某种特定的软件，他们可能与之怎样交互',
            type: cardEnum.IMAGE_ICON_DETAIL
        },
        {
            images: ImageCase01,
            images2: logo,
            description: '如果你既不热爱你的工作，也不对之嗤之以鼻，那说明你做的还不够多',
            type: cardEnum.IMAGE_ICON_DETAIL
        },
        {
            images: ImageCase01,
            images2: logo,
            description: '那些更棒的点子来自于个体依然用过去习惯的方式思考创意时——坐在办公桌前时，在咖啡店等咖啡时，洗澡时。这些由人们独自想出的点子更胜一筹。当讨论会的狂热劲头散去，头脑风暴产生的点子其实并没有那么特别',
            type: cardEnum.IMAGE_ICON_DETAIL
        }
    ]

    const cardObj = [
        {
            title: '作业效率低',
            type: cardEnum.IMAGE_TITLE_DETAIL,
            images: ico1,
            imageStyle: {
                width: '25%'
                , objectFit: 'fill', marginLeft: '37.5%'
            },
            titleStyle: {
                textAlign: 'center'
            },
            detail: '员工操作效率低\n' +
                '仓库摆放凌乱\n' +
                '货品流转率差'
        },
        {
            title: '退换货补发流程长',
            type: cardEnum.IMAGE_TITLE_DETAIL,
            images: ico2,
            imageStyle: {
                width: '25%'
                , objectFit: 'fill', marginLeft: '37.5%'
            },
            titleStyle: {
                textAlign: 'center'
            },
            detail: '退货繁琐且不及时\n' +
                '无法核实和追溯订单信息'
        },
        {
            title: '库存不精确',
            type: cardEnum.IMAGE_TITLE_DETAIL,
            images: ico3,
            imageStyle: {
                width: '25%'
                , objectFit: 'fill', marginLeft: '37.5%'
            },
            titleStyle: {
                textAlign: 'center'
            },
            detail: '不清楚商品总成本总库存\n' +
                '多店铺多平台多仓库无法库存同步\n' +
                '数据统计不及时，无法做正确决策'
        },

    ]

    const solutionCardObj = [
        {
            top: '40',
            icon: decrease,
            middle: '作业效率提升',
            bottom: '消息及时推送，任务智能分配，整体流程优化，部门高效协同，收货入库、库内管理、拣配出货移动化管理，效率大大提升。'
        },
        {
            top: '35',
            icon: increase,
            middle: '仓库利用率提升',
            bottom: '仓库空间合理规划，各类容器精准细分，人员、设备充分利用，有效减少资源浪费，提高仓库利用率。'
        },
        {top: '90', icon: increase, middle: '库存准确率提升', bottom: '库存高效盘点，仓库高度透明，信息实时查看，准确率大大提升。库存安全预警，大大降低库存风险，有效保证生产。'},
        {top: '85', icon: increase, middle: '订单交付准时率提升', bottom: '仓库周转效率提高，快速完成出入库，同时降低出错率。单次出货时间大大缩短，订单准时交付率大幅提升。'},
    ]

    const contentArr = [
        {title: '进货管理', detail: ['到货预约', '扫码收货', '移动检验', '感应入库', '上架指引', '快捷上架']},
        {title: '库内管理', detail: ['区域细分', '路径规划', '即时盘点', '批量移库', '库存速查', '库存预警']},
        {title: '库存管理', detail: ['商品库存', '商品库位', '库位库存', '库存预警', '批次管理', '库存总量']},
        {title: '出货管理', detail: ['出库策略', '任务分配', '拣货引导', '移动拣货', '扫码下架', '进度查询']},

    ]

    const caseArr = [
        {
            style: {
                backgroundImage: `url(${case1})`,
                backgroundSize: 'cover'
            }, title: '案例一', detail: '智能仓储提升订单交付能力'
        },
        {
            style: {
                backgroundImage: `url(${case2})`,
                backgroundSize: 'cover'
            }, title: '案例二', detail: '减少等待浪费 迈向精益生产'
        },
        {
            style: {
                backgroundImage: `url(${case3})`,
                backgroundSize: 'cover'
            }, title: '案例三', detail: '实时监控打造透明智能现场'
        },
        {
            style: {
                backgroundImage: `url(${case4})`,
                backgroundSize: 'cover'
            }, title: '案例四', detail: '智能车库管理让生产更有序'
        }
    ]

    const [bannerProductHeight, setBannerProductHeight] = useState(0)
    const [cardObj2, setCardObj2] = useState(cardObj2_DEMO)
    useEffect(() => {
        //获取bannerImg的高度
        setTimeout(() => {
            let bannerImg = document.querySelector('.img-banner')
            if (bannerImg.clientHeight) {
                setBannerProductHeight(bannerImg.clientHeight)
                // this.setState({bannerHeight:bannerImg.clientHeight})
            }
        }, 300)
        getCardObj2()
    }, [])

    const getCardObj2=()=>{
        // 获取导航栏路径
        let times=0
        const path = window.location.hash.replace('#', '');
            let interval = setInterval(() => {
            let menuList = JSON.parse(JSON.stringify(mobxstore.menuList))
            if (menuList && menuList.length > 0) {
                let currentMenu = menuList.find((item) => item.routeUrl == path)
                wbGetLaunchedCaseList(currentMenu.solutionCategoryId).then((res) => {
                    // this.setState({list: res.data.itemList});
                    let cardObj = _.map(res.data.itemList, ({caseImageUrl, customerLogoUrl, description}) => ({
                        images: config.SERVER_URL + caseImageUrl,
                        images2: config.SERVER_URL + customerLogoUrl,
                        description,
                        type: cardEnum.IMAGE_ICON_DETAIL
                    }));
                    setCardObj2(cardObj)
                    // this.setState({cardObj2: cardObj})
                })
                clearInterval(interval)
            }else {
                times++;
                if(times>10){
                    clearInterval(interval)
                }
            }

        }, 100)
    }

    return (
        <div className="solutionHead">
            <div style={{display: 'flex'}}>
                <div>
                    <div className="banner-join" style={{position: 'relative'}}>
                        <img className='img-banner' src={banner} alt="banner solution"/>
                        <div className={style.fontTitle} style={{width: `${displayWidth}px`}}>
                            <div className='banner-font' style={{textAlign: 'center'}}>仓储管理系统WMS解决方案</div>
                            <div className='banner-font-middle'
                                 style={{marginTop: '1rem', textAlign: 'start', fontWeight: 300, fontSize: '26px'}}>
                                对各行业、各仓库业务类型实现统一的精细化管理和物流协同优化，提供完善的作业策略，支持多种拣货规则，集成RF/PDA/手持设备应用。
                            </div>

                            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                <ButtonWrapper
                                    buttonStyle={{marginTop: '2rem'}}
                                    type={ButtonType.MIAN_FEI_SHI_YONG_WHITE}
                                >免费试用</ButtonWrapper>
                                <div style={{width: '15px'}}></div>
                                {/*<ButtonWrapper*/}
                                {/*    buttonStyle={{marginTop: '2rem'}}*/}
                                {/*    type={ButtonType.ZAI_XIAN_ZI_XUN}*/}
                                {/*>在线咨询</ButtonWrapper>*/}
                            </div>

                        </div>

                    </div>
                </div>
                <img className='banner-product' style={{
                    position: 'absolute',
                    width: displayWidth + 'px',
                    top: bannerProductHeight ? bannerProductHeight - 230 + 'px' : '420px',
                    transition: 'all 1s',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }} src={bannerProduct}/>
            </div>


            <div style={{width: '100%', height: '480px', backgroundColor: 'white'}}></div>
            <div>
                <TabsComponent
                    title='高效串联RF、移动设备、电商ERP之间的互动' tabsObjArr={tabsObjArr}
                    detail=''
                    onlyShowImage={true}
                    tabStyle={{backgroundColor:'white'}}
                />
            </div>

            <CardWrapper
                cardObj={cardObj}
                title="订单无法准时交付？厂内作业一团乱？"
                detail="您的企业是否正面临以下困扰？"
                cardStyle={{backgroundImage: `url(${problemBg})`, backgroundSize: 'cover', height: '32rem'}}
                cardWidth='30%'
            />

            <div className={style.container}>
                <div className={style.topIconContainer}>
                    <img src={arrow}/>
                    <img src={arrow}/>
                    <img src={arrow}/>
                </div>
                <div className={style.content}>
                    {contentArr.length > 0 && contentArr.map((item, index) => {
                        return (
                            <div key={index} className={style.contentItem}>
                                <div style={{fontWeight: 'bolder',fontSize:'20px'}}>{item.title}</div>
                                {item.detail.map((detailItem) => (
                                    <div key={detailItem} className={style.detailItem}>
                                        {detailItem}
                                    </div>
                                ))}
                            </div>
                        )
                    })}
                </div>
            </div>

            <CardWrapper
                // cardObj={cardObj}
                title="智能仓储管理解决方案"
                detail="仓储物流改善立竿见影，助力企业持续提质降本增效"
                cardStyle={{
                    backgroundImage: `url(${solutionBg})`,
                    backgroundSize: 'cover',
                    height: '700px',
                    color: 'white'
                }}
            >
                <div style={{marginLeft: '14%', width: '72%', display: 'flex', flexWrap: 'wrap'}}>
                    {solutionCardObj && solutionCardObj.map((item, index) => (
                        <div className={style.solutionBox} key={index}>
                            <div style={{
                                textAlign: 'center',
                                fontWeight: '600',
                                fontFamily: 'numberStyle',
                                color: '#ffd1ad'
                            }}>{item.top}<span
                                style={{fontSize: '2rem'}}>%</span><img style={{marginLeft: '1rem'}} src={item.icon}/>
                            </div>
                            <div style={{
                                textAlign: "center",
                                marginBottom: '0.3rem',
                                fontSize: '20px',
                                fontWeight: '900'
                            }}>{item.middle}</div>
                            <div style={{fontSize: '14px',textAlign:'center'}}>{item.bottom}</div>
                        </div>
                    ))}

                </div>
            </CardWrapper>

            {/*<CardWrapper*/}
            {/*    // cardObj={cardObj}*/}
            {/*    title="合作案例"*/}
            {/*    detail="应用效益备受认可，值得您信赖的伙伴！"*/}
            {/*    cardStyle={{paddingBottom:'2rem', backgroundColor: '#f4f5f5'}}*/}
            {/*>*/}
            {/*    <div style={{display:'flex',justifyContent:'space-between'}}>*/}
            {/*        {caseArr && caseArr.map((item,index) => (*/}
            {/*            <div key={index} style={{...item.style, width: '15rem', height: '20rem',borderRadius:'10px'}}>*/}
            {/*                <div style={{marginTop:'6rem',textAlign:"center",color:"white"}}>*/}
            {/*                    <div style={{fontWeight:"bolder"}}>{item.title}</div>*/}
            {/*                    <div style={{fontSize:'12px',marginTop:'2rem'}}>{item.detail}</div>*/}

            {/*                </div>*/}

            {/*            </div>*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*</CardWrapper>*/}

            <CardWrapper
                title="合作案例"
                detail="应用效益备受认可，值得您信赖的伙伴！"
                cardObj={cardObj2}
                cardStyle={{paddingBottom: '50px', backgroundColor: 'white'}}
            />
        </div>
    );
}

export default SolutionHead4;
