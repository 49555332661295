/**
 * 卡片列表
 */
import Card from './Card'
import cardEnum from '../enum/cardEnum'
import ShowAnimate from "../../common/showAnimate";
import style from './style.module.css'
import {Link} from "react-router-dom";
import {Button} from "antd";
import React, {useEffect, useState} from 'react';
import icoArrow from "../../statics/index_2/ico_arrow_more.svg";

const CardWrapper = (props) => {
    const {cardWidth, children, cardObj, cardStyle, titlePosition, detail, title, imgSrc, cardObjMenu, fetchCaseList, maxLength} = props
    const [selectIndex, setSelectIndex] = useState(0);
    const [wrapCardMenu, setWrapCardMenu] = useState([])
    const displayWidth = 1200
    let selectMenu = (item, index) => {
        setSelectIndex(index)
        if (item.key == 'all') {
            fetchCaseList()
        } else {
            fetchCaseList('industryCategoryId', item.key)
        }
    }
    useEffect(() => {
        if (cardObjMenu) {
            let cloneMenu = JSON.parse(JSON.stringify(cardObjMenu))
            cloneMenu.unshift({
                caption: "所有",
                description: '全部',
                key: 'all'
            })
            setWrapCardMenu(cloneMenu)
        }
    }, [cardObjMenu])

    const renderCard = (item) => (
        <div>
            {item.type === cardEnum.IMAGE_TITLE_DETAIL ? (
                <div>
                    <Card title={item.title} image={item.images} type={item.type}
                          description={item.detail}
                          imageStyle={item.imageStyle}
                          titleStyle={item.titleStyle}
                          boxStyle={item.boxStyle}
                    />
                </div>
            ) : item.type === cardEnum.IMAGE_TITLE_IMAGE ? (
                <div>
                    <Card title={item.title} image={item.images} image2={item.images2} type={item.type}
                          tag={item.tag}/>
                </div>

            ) : item.type === cardEnum.IMAGE_ICON_DETAIL ? (
                <div>
                    <Card title={item.title} image={item.images} image2={item.images2} type={item.type}
                          tag={item.tag} description={item.description}/>
                </div>
            ) : ''
            }
        </div>
    )
    return (
        <div style={cardStyle}>
            {/*{title?<header className='hot-title' style={{*/}
            {/*    paddingTop: '3rem',*/}
            {/*    paddingBottom: '1rem'*/}
            {/*}}>{title}</header>:""}*/}

            {/*{title == '典型案例' ? <Link to="/customer">*/}
            {/*    <div className={style.cardLink}>更多案例></div>*/}
            {/*</Link> : ''}*/}

            {title == '典型案例' || title == '合作客户' ?
                (
                    <div style={{
                        width: displayWidth + 'px',
                        marginLeft: (window.screen.width - displayWidth) / 2 + 'px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <header className='hot-title' style={{
                            paddingTop: '5rem',
                            paddingBottom: '0rem',
                            textAlign: titlePosition == 'left' ? 'start' : ''
                        }}>{title}</header>
                        {title == '典型案例' ? (
                            <Link to="/customer">
                                <div className={style.cardLink}>更多案例
                                    <img className='cardLinkStyle' src={icoArrow}/>
                                </div>
                            </Link>) : title == '合作客户' ? (
                            (
                                <Link to="/copoperate">
                                    <div className={style.cardLink}>更多客户
                                        <img className='cardLinkStyle' src={icoArrow}/>
                                    </div>
                                </Link>)
                        ) : ''}

                    </div>
                )
                : <header className='hot-title' style={{
                    width: displayWidth + 'px',
                    marginLeft: (window.innerWidth - displayWidth) / 2 + 'px',
                    paddingTop: '100px',
                    paddingBottom: '0rem',
                    textAlign: titlePosition == 'left' ? 'start' : '',
                }}>{title}</header>}


            <div style={{
                textAlign: 'center',
                width: '50%',
                marginLeft: '25%',
                paddingBottom: '30px',
                fontSize: '16px',
                marginTop: '20px'
            }}>{detail}</div>

            {wrapCardMenu && wrapCardMenu.length > 0 ? (<div style={{
                display: 'flex',
                width: displayWidth + 'px',
                marginLeft: (window.screen.width - displayWidth) / 2 + 'px',
                paddingBottom: '55px'
            }}>
                {wrapCardMenu && wrapCardMenu.map((item, index) => (
                    <div
                        key={'menu_' + index}
                        className={selectIndex == index ? style.selectedMenu : style.unSelectMenu}
                        onClick={() => {
                            selectMenu(item, index)
                        }}
                    >{item.caption}</div>
                ))}
            </div>) : ''}

            <div
                className={style.flexWrapper}
                style={{
                    display: 'flex',
                    // justifyContent: 'center',
                    flexWrap: "wrap",
                    width: displayWidth + 'px',
                    marginLeft: (window.screen.width - displayWidth) / 2 + 'px',
                    justifyContent: cardObj && cardObj.length > 4 ? '' : cardObj && cardObj.length == 4 ? 'space-between' : cardWidth ? 'center' : ''
                }}
            >
                {cardObj && cardObj.map((item, index) => {
                    return (
                        <div key={index} style={{
                            width: cardWidth ? cardWidth : cardObj.length <= 3 ? '30%' : cardObj.length == 4 ? '24%' : '25%',
                            marginRight: cardObj && cardObj.length >= 4 ? '' : '10px',
                            padding: cardObj.length > 4 ? '5px 5px' : 0
                        }}>
                            {maxLength && index < maxLength ? (
                                renderCard(item)
                            ) : index >= maxLength ? '' : (
                                renderCard(item)
                            )}

                        </div>
                    )
                })}

                {
                    imgSrc ? <div style={{margin: '0 auto'}}>
                        <img src={imgSrc} style={{width: '100%', paddingBottom: '3rem'}}/>
                    </div> : ''
                }
            </div>
            {children ? (
                <div className={style.childBox}
                     style={{
                         width: displayWidth + 'px',
                         marginLeft: (window.screen.width - displayWidth) / 2 + 'px',
                         marginTop: '32px'
                     }}>
                    {children}
                </div>
            ) : ''}


            <div className={style.tail}></div>
        </div>
    );
}

export default CardWrapper;
