import {  post } from "./http";
import axios,{get} from 'axios';
import $,{jQuery} from 'jquery';
import config from "../config";

const HOST_URL=config.SERVER_URL; //Company2
// 访问权限获取
//获取优秀案例
export const getOutstandingCaseList = () =>
  get("/case/getOutstandingCaseList" );
export const signIn = params => post("/rest/services/login", params);
export const getSolutionList = () => get("/solution/getSolutionList");

// wbGetSolution, 获取方案详情
export const wbGetSolutionDetail=(solutionId = "SL2022092710001")=> {
    // let solutionId = "SL2022092710001";
    let jsonData = {
        "solutionId": solutionId
    };
    return $.ajax({
        url: HOST_URL + '/rest/services/wbGetSolution',
        type: 'GET',
        //xhrFields: {withCredentials: true},
        dataType: 'json',
        headers: {
            // "Authorization": sessionStorage.getItem('token'),
            "Content-Type": "application/json;charset=UTF-8",
            "Accept": "*/*"
        },
        data: 'inParams=' + encodeURIComponent(JSON.stringify(jsonData), 'utf-8'),

    });
}

//获取热门方案
export const getHotSolution=()=>{
    let sortField;
    let searchList;
    let solutionId = null;
    sortField = "solutionId DESC"; //ASC - 升序， DESC -降序
    searchList = {
        "solutionId": solutionId
    };
    let jsonData = {
        "viewIndex": 1,  //start from 1
        "viewSize": 20,
        "sortField": sortField,
        "searchList": searchList
    };
    return $.ajax({
        url: HOST_URL + '/rest/services/wbGetSolutionList',
        type: 'GET',
        //xhrFields: {withCredentials: true},
        dataType: 'json',
        headers: {
            // "Authorization": sessionStorage.getItem('token'),
            "Content-Type": "application/json;charset=UTF-8",
            "Accept": "*/*"
        },
        data: 'inParams=' + encodeURIComponent(JSON.stringify(jsonData), 'utf-8'),

    });
}

//获取下拉列表的数据
export const getTypeList=({fieldName})=>{
    let jsonData={
        // industryCategoryId:"QD_WEB_IND_INDUSTRY",
        fieldName: fieldName,
    };
    return axios.get(HOST_URL + '/rest/services/getTypeList',{
            params:{inParams:JSON.stringify(jsonData)},
        headers:{
            // Authorization:sessionStorage.getItem('token'),
            'Content-Type':'application/json;charset=UTF-8',
        },
    });
}

//获取项目列表
export const wbGetProjectList=(viewIndex=1,viewSize=10)=>{
    let sortField;
    let searchList;
    let projectCategoryId = null;
    sortField = "projectId DESC"; //ASC - 升序， DESC -降序
    searchList = {
        "projectCategoryId": projectCategoryId
    };
    let jsonData = {
        viewIndex: viewIndex,  //start from 1
        viewSize: viewSize,
        sortField: sortField,
        searchList: searchList
    };
    return $.ajax({
        url: HOST_URL + '/rest/services/wbGetProjectList',
        type: 'GET',
        //xhrFields: {withCredentials: true},
        dataType: 'json',
        headers: {
            "Authorization": sessionStorage.getItem('token'),
            "Content-Type": "application/json;charset=UTF-8",
            "Accept": "*/*"
        },
        data: 'inParams=' + encodeURIComponent(JSON.stringify(jsonData), 'utf-8'),

    });
}

//创建项目
export const wbCreateProject=({projectName,projectCategoryId,preCost,planDate})=>
{

    let jsonData = {
        projectName: projectName,  //项目名称，必填
        projectCategoryId: projectCategoryId,  //项目分类(类型) ，必填
        preCost:preCost,
        planDate: planDate,  //项目开始日期，必填
        // "organizationPartyId": organizationPartyId,  //公司partyId
        // "contactPartyId": contactPartyId,  //联系人partyId
        // "contactNumber": contactNumber,  //联系人电话
    };
    return $.ajax({
        url: HOST_URL + '/rest/services/wbCreateProject',
        type: 'POST',
        //xhrFields: {withCredentials: true},
        dataType: 'json',
        headers: {
            "Authorization": sessionStorage.getItem('token'),
            "Content-Type": "application/json;charset=UTF-8",
            "Accept": "*/*"
        },
        data: JSON.stringify(jsonData),

    });
}

//获取已上线案例列表 customer.jsx
export const wbGetLaunchedCaseList=(caseCategoryId)=>{
    let sortField;
    let searchList;
    let caseId = null;
    //let caseCategoryId=null; //QD_WEB_CAT_ECOMM
    sortField = "caseId DESC"; //ASC - 升序， DESC -降序
    searchList = {
        caseCategoryId,
        "caseId": caseId
    };
    let jsonData = {
        "viewIndex": 1,  //start from 1
        "viewSize": 20,
        "sortField": sortField,
        "searchList": searchList
    };
    return $.ajax({
        url: HOST_URL + '/rest/services/wbGetLaunchedCaseList',
        type: 'GET',
        //xhrFields: {withCredentials: true},
        dataType: 'json',
        headers: {
            // "Authorization": sessionStorage.getItem('token'),
            "Content-Type": "application/json;charset=UTF-8",
            "Accept": "*/*"
        },
        data: 'inParams=' + encodeURIComponent(JSON.stringify(jsonData), 'utf-8'),

    });
}

//获取案例详情
export const wbGetCase=(caseId="PD2022092310001")=> {
    // let caseId = "PD2022092310001";
    let jsonData = {
        "caseId": caseId
    };
        return axios.get(HOST_URL + '/rest/services/wbGetCase',{
            params:{inParams:JSON.stringify(jsonData)},
            headers:{
                // Authorization:sessionStorage.getItem('token'),
                'Content-Type':'application/json;charset=UTF-8',
            },
        });
}

//发送(获取)短信验证码
export const sendVerifyCodeAsSms=({phoneNumber,templateTypeId})=>{
    let jsonData = {
        "phoneNumber": phoneNumber,  //手机号码
    };

    if(templateTypeId){
        jsonData.templateTypeId=templateTypeId
    }
    return axios.post(`${config.SERVER_URL}/rest/services/sendVerifyCodeAsSms`, jsonData)
}