import React from 'react';
import {
  Row,
  Col
} from 'antd';
import style from './style.module.css';
import banner from '../../statics/cooperate/banner_1.png';
import cooperation from '../../statics/about/company-list.jpg';
import ShowAnimate from "../../common/showAnimate";
import CardWrapper from "../../component/CardComponent";
import cardEnum from "../../component/enum/cardEnum";
import classic1 from "../../statics/index_2/img_case_01.png";
import classicA from "../../statics/index_2/ico_QRcode.svg";
import classic2 from "../../statics/index_2/img_case_02.png";
import classicB from "../../statics/index_2/ico_QRcode.svg";
import classic3 from "../../statics/index_2/img_case_03.png";
import classicC from "../../statics/index_2/ico_QRcode.svg";
import classic4 from "../../statics/index_2/img_case_04.png";
import classicD from "../../statics/index_2/ico_QRcode.svg";
import ScrollView from "../../component/ScrollViewComponent";
import ErrorBoundary from "../../common/errorBoundary";
import scrollViewPic0 from "../../statics/scrollView/1_01.png"
import scrollViewPic1 from "../../statics/scrollView/1_02.png";
import scrollViewPic2 from "../../statics/scrollView/1_03.png";
import scrollViewPic3 from "../../statics/scrollView/1_04.png";
import scrollViewPic4 from "../../statics/scrollView/1_05.png";
import scrollViewPic5 from "../../statics/scrollView/1_06.png";
import scrollViewPic6 from "../../statics/scrollView/1_07.png";
import scrollViewPic7 from "../../statics/scrollView/1_08.png";
import scrollViewPic8 from "../../statics/scrollView/1_09.png";
import scrollViewPic9 from "../../statics/scrollView/1_10.png";
import scrollViewPic10 from "../../statics/scrollView/1_11.png";
import scrollViewPic11 from "../../statics/scrollView/1_12.png";
import  scrollViewPic12 from "../../statics/scrollView/1_13.png";
import  scrollViewPic13 from "../../statics/scrollView/1_14.png";
import scrollViewPic14 from "../../statics/scrollView/1_15.png";
import scrollViewPic15 from "../../statics/scrollView/1_16.png";
import scrollViewPic16 from "../../statics/scrollView/2_01.png";
import scrollViewPic17 from "../../statics/scrollView/2_02.png";
import scrollViewPic18 from "../../statics/scrollView/2_03.png";
import scrollViewPic19 from "../../statics/scrollView/2_04.png";
import scrollViewPic20 from "../../statics/scrollView/2_05.png";
import scrollViewPic21 from "../../statics/scrollView/2_06.png";
import scrollViewPic22 from "../../statics/scrollView/2_07.png";
import scrollViewPic23 from "../../statics/scrollView/2_08.png";
import scrollViewPic24 from "../../statics/scrollView/2_09.png";
import scrollViewPic25 from "../../statics/scrollView/2_10.png";
import scrollViewPic26 from "../../statics/scrollView/2_11.png";
import scrollViewPic27 from "../../statics/scrollView/2_12.png";
import scrollViewPic28 from "../../statics/scrollView/2_13.png";
import scrollViewPic29 from "../../statics/scrollView/2_14.png";
import scrollViewPic30 from "../../statics/scrollView/2_15.png";
import scrollViewPic31 from "../../statics/scrollView/2_16.png";
import scrollViewPic32 from "../../statics/scrollView/2_17.png";
import scrollViewPic33 from "../../statics/scrollView/3_01.png";
import scrollViewPic34 from "../../statics/scrollView/3_02.png";
import scrollViewPic35 from "../../statics/scrollView/3_03.png";
import scrollViewPic36 from "../../statics/scrollView/3_04.png";
import scrollViewPic37 from "../../statics/scrollView/3_05.png";
import scrollViewPic38 from "../../statics/scrollView/3_06.png";
import scrollViewPic39 from "../../statics/scrollView/3_07.png";
import scrollViewPic40 from "../../statics/scrollView/3_08.png";




export default class Coperate extends React.Component {

	constructor(props) {
		super(props);
		const cardObj2=[
			{title:'供享生活',type:cardEnum.IMAGE_TITLE_IMAGE,images:classic1,images2:classicA,tag:'#百货商超'},
			{title:'照片管理系统',type:cardEnum.IMAGE_TITLE_IMAGE,images:classic2,images2:classicB,tag:'#其他'},
			{title:'艺泰乐器',type:cardEnum.IMAGE_TITLE_IMAGE,images:classic3,images2:classicC,tag:'#其他'},
			{title:'麦扣扣',type:cardEnum.IMAGE_TITLE_IMAGE,images:classic4,images2:classicD,tag:'#家居百货'},
		]
		const scrollViewArr = [scrollViewPic0,scrollViewPic1, scrollViewPic2, scrollViewPic3, scrollViewPic4, scrollViewPic5, scrollViewPic6, scrollViewPic7, scrollViewPic8, scrollViewPic9, scrollViewPic10, scrollViewPic11,scrollViewPic12,scrollViewPic13,scrollViewPic14,scrollViewPic15]
		const scrollViewArr2 = [ scrollViewPic16, scrollViewPic17, scrollViewPic18, scrollViewPic19, scrollViewPic20,scrollViewPic21,scrollViewPic22,scrollViewPic23,scrollViewPic24,scrollViewPic25,scrollViewPic26,scrollViewPic27,scrollViewPic28,scrollViewPic29,scrollViewPic30,scrollViewPic31,scrollViewPic32]
		const scrollViewArr3 = [scrollViewPic33,scrollViewPic34, scrollViewPic35, scrollViewPic36, scrollViewPic37, scrollViewPic38, scrollViewPic39, scrollViewPic40]


		this.state={
			cardObj2,
			scrollViewArr,
			scrollViewArr2,
			scrollViewArr3
		}
	}

	componentDidMount() {

	}

	render(){
		const {cardObj2,scrollViewArr,scrollViewArr2,scrollViewArr3}=this.state
		return(
			<div className="joinus" style={{backgroundColor:'white'}}>
				<Row type="flex" justify="center">
					<Col span={24} >
						<div className={style.bannerJoin}>
							<img src={banner} alt="banner cooperation"/>
						</div>
					</Col>
				</Row>


				<Row type="flex" justify="center" style={{paddingTop:'0rem'}}>
					<Col span={24} >
						<CardWrapper
							title='工业、科技'
							titlePosition='left'
							cardStyle={{backgroundColor:'#f3f3f3'}}
						>
							<ScrollView scrollViewArr={scrollViewArr}></ScrollView>
						</CardWrapper>

						<CardWrapper
							title='商业、食品'
							titlePosition='left'
							cardStyle={{backgroundColor:'#f3f3f3'}}
						>
							<ScrollView scrollViewArr={scrollViewArr2}></ScrollView>
						</CardWrapper>

						<CardWrapper
							title='物流、供应链'
							titlePosition='left'
							cardStyle={{backgroundColor:'#f3f3f3'}}
						>
							<ScrollView scrollViewArr={scrollViewArr3}></ScrollView>
						</CardWrapper>

						<div style={{width:'100%',height:'80px',backgroundColor:'#f3f3f3'}}></div>

					</Col>
				</Row>
				
				

			</div>
		);
	}
}