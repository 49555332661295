import React, {Component} from 'react';
import style from './index.css';
class ScrollView extends Component {
    constructor(props) {
        super(props);
        const {scrollViewArr} = this.props
        this.isPaused=false
        this.state = {
            viewArr: scrollViewArr
        }
    }

    // componentDidMount() {
    //     // 获取轮播容器和图片
    //     const carousel = document.querySelector('.image-carousel');
    //     const images = carousel.querySelectorAll('img');
    //     // 获取每张图片宽度
    //     let imageWidth = images[0].clientWidth;
    //
    //     // 设置图片容器宽度为所有图片宽度之和
    //     let carouselWidth = imageWidth * (images.length + 1);
    //     carousel.style.width = `${carouselWidth}px`;
    //
    //     // 定义循环滚动函数
    //     let counter = 0;
    //     let _this = this
    //     function loop() {
    //         setInterval(() => {
    //             if(!_this.isPaused){
    //                 setTimeout(()=>{
    //                     _this.rotateArray() //把数组第一个元素放到最后
    //                     carousel.style.transition = 'none';  // 取消动画效果
    //                     carousel.style.transform = `translateX(0px)`;  // 将轮播容器移回起点
    //                 },800)
    //                 carousel.style.transition = 'transform 0.8s linear';  // 添加动画效果'
    //                 carousel.style.transform = `translateX(-${150 * (counter + 1)}px)`;
    //             }
    //
    //
    //         }, 2000);  // 2秒后滚动到下一张图片
    //     }
    //
    //     loop();
    // }

    pauseInterval=()=> {
        this.isPaused = true;
    }

    resumeInterval() {
        this.isPaused = false;
    }

    rotateArray = () => {
        const {viewArr} = this.state;
        const firstItem = viewArr.shift();
        this.setState({viewArr: [...viewArr, firstItem]});
    };

    render() {
        const {viewArr} = this.state
        return (
            <div className='scroll-view-container' onMouseEnter={()=>{
                this.pauseInterval()
            }}
            onMouseLeave={()=>{this.resumeInterval()}}
            >
                <div className='image-carousel' style={{width:'100%',display:'flex',flexWrap:'wrap'}}>
                    {viewArr && viewArr.map((item, index) => (
                    <div
                        key={'scroll_img_'+index}
                        style={{
                            // backgroundColor: 'black',
                            marginRight: '16px',
                            minWidth: '260px',
                            height: '120px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent:'center',

                            marginTop:'20px'

                    }}>
                        <img style={{height:'auto'}} src={item}/>
                    </div>
                ))}</div>

            </div>
        );
    }
}

export default ScrollView;