import React from "react";
import { Row, Col, Card } from "antd";
import { Link } from "react-router-dom";
import "./style.css";
import {wbGetLaunchedCaseList, wbGetSolutionDetail,getTypeList} from "../../config/api";
import config from "../../config";
const { Meta } = Card;
const metastyle = {
    textAlign: "center",
    padding: "1em"
};

let solutionId;

export default class SolutionCase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            solutionDetailIcon: [],
            solutionAdvantage: [],
            caseList: [],
            solutionInfo:[]
        };
    }
    /**
     * 异步请求解决方案数据
     */
    componentDidMount() {
        const { location } = this.props;
        let params = new URLSearchParams(location.search);
        let solutionId = params.get("id");
        //获取解决方案详情
        wbGetSolutionDetail(solutionId).then(res=>{
            this.setState({ solutionInfo: res.data.itemInfo,solutionDetailIcon: res.data.itemInfo.solutionAttrList});
        })


        let params2 = new URLSearchParams(location.search);
        let solutionCategoryId = params2.get("solutionCategoryId");
        //获取客户案例列表
        wbGetLaunchedCaseList(solutionCategoryId).then((res)=>{
            this.setState({caseList:res.data.itemList})

        })
        // getSolutionCaseById({solutionId:solutionId}).then(res => {
        //     debugger;
        //     this.setState({ solutionInfo: res.content.solutionInfo });
        //     this.setState({ solutionDetailIcon: res.content.solutionDetailIcon });
        // });
        //
        // getCaseListBySolutionId({solutionId:solutionId}).then(res => {
        //     debugger;
        //     this.setState({
        //         caseList: res.content
        //     });
        // });
    }

    render() {
        const{solutionDetailIcon,caseList,solutionInfo}=this.state
        const { location } = this.props;
        let params = new URLSearchParams(location.search);
        solutionId = params.get("id");

        return (
            <div className="solutionHead">
                <Row type="flex" justify="center">
                    <Col span={24}>
                        <div className="banner-join">
                            <img
                                src={`${config.SERVER_URL}${solutionInfo&&solutionInfo.bannerImageList&&solutionInfo.bannerImageList[0]}`}
                                alt="banner solution"
                            />
                        </div>
                    </Col>
                </Row>

                <div className="solution-meddle">
                    <Row type="flex" justify="center">
                        <Col xl={16} lg={16} sm={20} xs={20}>
                            <Row type="flex" justify="center">
                                <Col xl={6} lg={10} style={{display:'flex'}}>
                                    <div className="case-name">
                                        {this.state.solutionInfo.name}解决方案
                                    </div>
                                </Col>
                            </Row>
                            <p>{this.state.solutionInfo.webDescription}</p>

                            <Row
                                type="flex"
                                justify="center"
                                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                            >
                                {solutionDetailIcon&&solutionDetailIcon.map(item => {
                                    return (
                                        <Col xl={6} lg={6} key={item.id}>
                                            <img src={`${config.SERVER_URL}${item.attrImageUrlList[0]}`} alt="dev" />
                                            <div className="icon-title">{item.attrValue}</div>
                                            <div className="icon-desc">{item.attrDescription}</div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className="solution-bottom">
                    <Row type="flex" justify="center">
                        <Col xl={16} lg={16} md={22} sm={22} xs={22}>
                            <h1>客户案例</h1>
                            <Row
                                type="flex"
                                justify="start"
                                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                            >
                                {caseList&&caseList.map(item => {
                                    return (
                                        <Col
                                            sm={12}
                                            xs={22}
                                            className="gutter-row solutioncase"
                                            key={item.caseId}
                                        >
                                            {/* <Link to="/solution/case/detail"> */}
                                            <Link
                                                to={{
                                                    pathname: "/solution/case/detail",
                                                    search: "id=" + item.caseId
                                                }}
                                            >
                                                <Card
                                                    hoverable
                                                    cover={<img
                                                                alt="example"
                                                                className='solutionCase-img'
                                                                src={`${config.SERVER_URL}${item.originalImageUrl}`} />}
                                                >
                                                    <Meta
                                                        style={metastyle}
                                                        title={item.caseName}
                                                        description={item.caseDescription}
                                                    />
                                                </Card>
                                            </Link>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
