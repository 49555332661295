import './style.css';
import 'moment/locale/zh-cn';
import React from 'react';
import {
    Button,
    Divider,
    DatePicker,
    Form,
    Input,
    Select,
    message,
    } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import demand from '../../statics/logindemand/banner-demand.png'
import {getTypeList,wbCreateProject} from '../../config/api'
import mobxstore from "../../mobxStore/store";
 class LoginDemand extends React.Component {

    constructor(props) {
        super(props);

        message.config({
            maxCount: 1,
        });

        this.state={
            alertMessage:false,
            projectTypeValues:[]
        }
    }


    componentDidMount() {
        let fieldName='projectCategoryId'
        getTypeList({fieldName}).then((res)=>{

            let projectTypeValues=res.data.data.values
            mobxstore.setProjectType(projectTypeValues)
            this.setState({projectTypeValues})

        })

    }

    submitForm=()=>{
        const{projectTypeValues}=this.state
        let projectType =document.querySelector('.projectType').innerText
        let preCost=document.querySelector('.preCost').innerText
        let projectName=document.querySelector('.projectName').value
        let startTime=document.querySelector('.startTime input').value

        let projectTypeObj=projectTypeValues.find((item)=>item.caption==projectType)
        if(projectTypeObj) {
            projectType = projectTypeObj.key;
        }
        if(projectType&&preCost&&projectName&&startTime){
            wbCreateProject({projectName,projectCategoryId:projectType,preCost,planDate:startTime}).then((res)=>{
                this.setState({alertMessage:true})
             setTimeout(()=>{
                 this.setState({alertMessage:false})
             },5000)
            }).catch((e)=>{
                alert('请求发生异常')
            })
        }

    }

   alertMessage=()=>{
       message.success('项目创建成功！');
   }

     handleSubmit = e => {
         e.preventDefault();
         this.props.form.validateFields((err, values) => {
             if (!err) {
                 console.log('Received values of form: ', values);
             }
         });
     };

     onShowSizeChange(current, pageSize) {
         console.log(current, pageSize);
     }

    render() {
        const {alertMessage,projectTypeValues}=this.state
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                {alertMessage?
                    <div>{this.alertMessage()}</div>:''}

                <header className='login-demand-header'>
                    <img src={demand}/>
                </header>
                <div className="ant-row-flex ant-row-flex-center">
                    <div className="ant-col-16">
                        <Divider plain="true"><span className='demand-text'>请告诉我们您的需求</span></Divider>
                        <div className="form-container ant-row-flex ant-row-flex-center">
                            <div className="ant-col-10">
                                <Form
                                    onSubmit={this.handleSubmit}
                                    ref={(e)=>{this.projectName=e}}
                                    autoComplete="off"
                                    layout="horizontal"
                                    // onValuesChange={onFormLayoutChange}
                                    size="default"
                                >

                                    <Form.Item label="项目名称"
                                               name="projectName"
                                               // validateTrigger='onBlur'
                                              >
                                        {getFieldDecorator('projectName', {
                                            rules: [{ required: true, message: '项目名称是必填项' }],
                                        })(
                                        <Input placeholder="请填写您的项目名称" size="large" className="projectName"/>
                                        )}
                                        </Form.Item>

                                    <Form.Item label="项目类型" name="projectType">
                                        {getFieldDecorator('projectType', {
                                            rules: [{ required: true, message: '项目类型是必填项' }],
                                        })(
                                        <Select placeholder="- 请选则项目类型 -" size="large" className="projectType">
                                            {projectTypeValues.length>0&&projectTypeValues.map((item,index)=>{

                                                return <Select.Option key={index} value={`value_${index}`} >{item.caption}</Select.Option>

                                            })}
                                        </Select>
                                        )}
                                    </Form.Item>

                                    <Form.Item label="预算" name="preCost">
                                        {getFieldDecorator('preCost', {
                                            rules: [{ required: true, message: '预算是必填项' }],
                                        })(
                                        <Select placeholder="- 请选则预算范围 -" size="large" className='preCost'>
                                            <Select.Option value="demo" >Demo</Select.Option>
                                            <Select.Option value="demo2">Demo2</Select.Option>
                                        </Select>
                                        )}
                                    </Form.Item>


                                    <Form.Item label="开始时间" rules={[{ required: true }]} name="startTime">
                                        {getFieldDecorator('startTime', {
                                            rules: [{ required: true, message: '开始时间是必填项' }],
                                        })(
                                        <DatePicker className="startTime" locale={locale} style={{width:'100%'}} size="large" placeholder="- 请选则时间 -" showTime format="YYYY-MM-DD HH:mm:ss" />
                                        )}
                                        </Form.Item>

                                    <Form.Item label="" name="btn">
                                        <Button type="primary" htmlType="submit" size="large" style={{width:'100%'}} onClick={()=>{this.submitForm()}}>开始</Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>

                    </div>

                </div>
                <footer style={{paddingBottom:'80px'}}></footer>

            </div>
        )
    }


}


export default Form.create()(LoginDemand)