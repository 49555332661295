import React, {useEffect, useRef} from 'react';
import './style.css'

const ShowAnimate = (props) => {
    const {children} = props
    // let randomNum= Math.floor(Math.random() * 9999);

    // 创建一个名为 inputRef 的引用
    const inputRef = useRef(null);

    function activeAnimation() {
        // 获取要判断的元素
        // let element = document.querySelector('.tools-animation-component'+randomNum)
        let element=inputRef.current

        // 获取元素的边界信息
        const rect = element && element.getBoundingClientRect();
        // 判断元素是否在屏幕可见区域内
        if (!rect) {
            return
        }

        // rect.top<window.innerHeight,表示div离顶部距离小于网页高度（即div显示在屏幕中）
        if (
            rect &&
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.top<window.innerHeight
        ) {
            // 元素完全或部分显示在屏幕上
            element.classList.add('tools-show-animate')
        } else {
            // element.classList.remove('tools-show-animate')

        }
    }


    useEffect(() => {
        activeAnimation()
        // 获取滚动条所在的元素（一般是window对象）
        const scrollElement = window;
        // 监听滚动事件
        scrollElement.addEventListener('scroll', function () {
          activeAnimation()
        });


    }, [])
  return <div ref={inputRef}>{children}</div>
}

export default ShowAnimate;
