import React, {Component} from 'react';
import './style.css';
import {Row, Col, Form, Icon, Input, Button, Checkbox, Select, notification} from 'antd';
import _ from 'lodash';
import banner from "../../statics/projectExp/banner_1.png";
import style from "../solution4/style.module.css";
import {fetchProvinceList, fetchCityList, fetchDistrictList} from "../../actions/fetchDataAction";
import {createCustomerRequest} from "../../actions/postAction";
import {Modal} from 'antd';
import Login from "../../pages/login";

class ProjectExp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            provinceList: [],
            cityList: [],
            districtList: [],
            provinceGeoId: '',
            cityGeoId: ''

        }

    }

    openNotificationWithIcon = (type, desc) => {
        notification[type]({
            message: '提示',
            description: desc,
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            this.setState({isLoading: true})

            if (!err) {
                console.log('Received values of form: ', values,_.find(this.state.districtList, {geoId: values.district}));


                const mergeName = _.find(this.state.districtList, {geoId: values.district}).mergename;

                let desc = '';
                if (values.distributionManage) {
                    desc += '电商小程序';
                }
                if (values.oderManage) {
                    if (values == '') {
                        desc += '订单管理OMS'
                    } else {
                        desc += ',订单管理OMS'
                    }

                }

                if (values.storeManage) {

                    if (values == '') {
                        desc += '仓储管理WMS'
                    } else {
                        desc += ',仓储管理WMS'
                    }

                }

                let data = {
                    name: values.name,
                    phone: values.phone,
                    companyName: values.companyName,
                    province: values.province,
                    city: values.city,
                    district: values.district,
                    address: mergeName,
                    description: desc
                }

                createCustomerRequest(data).then((res) => {
                    this.setState({isLoading: false})
                    this.openNotificationWithIcon('success', res && res.data && res.data.statusDescription)
                    console.log('创建成功', res, res.data, res.data.statusDescription)
                }).catch((e) => {
                    const statusCode = e.response.status;
                    if (statusCode == 401) {
                        Modal.error({
                            title: '提示',
                            content: '请登录...',
                        });
                    } else {
                        alert('未知错误，请联系项目管理员')
                    }
                })
            } else {
                this.setState({isLoading: true})
            }
        });
    };

        componentDidMount() {
            fetchProvinceList().then((res) => {
                if (res.data && res.data.data) {
                    this.setState({provinceList: res.data.data.itemList})
                }

            })
        }


    getCityList(provinceGeoId) {
        fetchCityList(provinceGeoId).then((res) => {
            if (res && res.data && res.data.data) {
                this.setState({cityList: res.data.data.itemList})
            }

        })
    }

    getDistrictList(cityGeoId) {
        fetchDistrictList(cityGeoId).then((res) => {
            if (res && res.data && res.data.data) {
                this.setState({districtList: res.data.data.itemList})
                // console.log(res)
            }

        })
    }

    changeSelect = (value, level) => {
        if (level == 1) {
            this.setState({provinceGeoId: value})
            //修改了省，需要清空市和区
            this.props.form.resetFields(['city', 'district']);
            if (value) {
                //重新获取市的数据
                this.getCityList(value)
            } else {
                //value不存在，说明是手动清空了select，所以设置cityList,districtList为空
                this.setState({cityList: [], districtList: []})
            }


        } else if (level == 2) {

            this.setState({cityGeoId: value})
            //修改了市，需要清空区
            this.props.form.resetFields(['district']);
            if (value) {
                //重新获取区的数据
                this.getDistrictList(value)
            } else {
                //value不存在，说明是手动清空了select，所以设置districtList为空
                this.setState({districtList: []})
            }

        }

    }

    render() {
        const {provinceList, cityList, districtList} = this.state
        const {getFieldDecorator} = this.props.form;
        const {Option} = Select;

        //如果没有登录，则先跳转到登录界面，登录完成后再跳回来
        const token = sessionStorage.getItem('token');
        return (
            <div>
                {token ? (
                    <div style={{backgroundColor: 'white'}}>
                        <div className="solutionHead">
                            <Row type="flex" justify="center">
                                <Col span={24}>
                                    <div style={{position: 'relative'}}>
                                        <img src={banner} alt="banner solution" style={{width: '100%'}}/>
                                        <div className={style.fontTitle}>
                                            <div className='banner-font' >申请试用</div>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div style={{textAlign: 'center', paddingTop: '2rem'}}>请填写一下您的信息，我们会尽快和您联系。</div>
                        <div className="project-form-box">

                            <Form onSubmit={this.handleSubmit} className="login-form">
                                <Form.Item label="姓名">
                                    {getFieldDecorator('name', {
                                        rules: [{required: true, message: '该项是必填选项'}],
                                    })(
                                        <Input
                                            // prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            placeholder="请输入"
                                        />,
                                    )}
                                </Form.Item>

                                <Form.Item label="手机">
                                    {getFieldDecorator('phone', {
                                        rules: [{required: true, message: '该项是必填选项'}],
                                    })(
                                        <Input
                                            type="text"
                                            placeholder="请输入"
                                        />,
                                    )}
                                </Form.Item>

                                <Form.Item label="公司">
                                    {getFieldDecorator('companyName', {
                                        rules: [{required: true, message: '该项是必填选项'}],
                                    })(
                                        <Input
                                            type="text"
                                            placeholder="请输入"
                                        />,
                                    )}
                                </Form.Item>


                                <Form.Item label="省">
                                    {getFieldDecorator('province', {
                                        rules: [{required: true, message: '该项是必填选项'}],
                                    })(
                                        <Select
                                            allowClear={true}
                                            showSearch
                                            placeholder="请输入"
                                            optionFilterProp="children"
                                            onChange={(value) => {
                                                this.changeSelect(value, provinceList[0] && provinceList[0].level)
                                            }}
                                        >
                                            {provinceList && provinceList.map((item) => (
                                                <Option value={item.geoId} key={item.webKey}>{item.geoName}</Option>
                                            ))}
                                        </Select>,
                                    )}
                                </Form.Item>

                                <Form.Item label="市">
                                    {getFieldDecorator('city', {
                                        rules: [{required: true, message: '该项是必填选项'}],
                                    })(
                                        <Select
                                            ref={(e) => this.city = e}
                                            // loading="true"
                                            allowClear={true}
                                            showSearch
                                            placeholder="请输入"
                                            optionFilterProp="children"
                                            onChange={(value) => {
                                                this.changeSelect(value, cityList[0] && cityList[0].level)
                                            }}
                                        >
                                            {cityList && cityList.map((item, index) => (
                                                <Option value={item.geoId} key={item.webKey}>{item.geoName}</Option>
                                            ))}
                                        </Select>,
                                    )}
                                </Form.Item>


                                <Form.Item label="区">
                                    {getFieldDecorator('district', {
                                        rules: [{required: true, message: '该项是必填选项'}],
                                    })(
                                        <Select
                                            allowClear={true}
                                            showSearch
                                            placeholder="请输入"
                                            optionFilterProp="children"
                                        >
                                            {districtList && districtList.map((item, index) => (
                                                <Option value={item.geoId} key={item.webKey}>{item.geoName}</Option>
                                            ))}
                                        </Select>,
                                    )}
                                </Form.Item>


                                <div className="project-item-title">你想要咨询哪些功能？</div>
                                <div style={{display: 'flex', justifyContent: 'space-between',flexDirection:'column'}}>
                                    <div className='check-box-item'>{getFieldDecorator('oderManage', {
                                        valuePropName: 'checked',
                                        initialValue: true,
                                    })(<Checkbox>订单管理OMS</Checkbox>)}
                                    </div>
                                    <div className='check-box-item'>{getFieldDecorator('storeManage', {
                                        valuePropName: 'checked',
                                        initialValue: false,
                                    })(<Checkbox>仓储管理WMS</Checkbox>)}</div>
                                    <div className='check-box-item'>{getFieldDecorator('distributionManage', {
                                        valuePropName: 'checked',
                                        initialValue: false,
                                    })(<Checkbox>电商小程序</Checkbox>)}</div>
                                    {/*{getFieldDecorator('financialManage')(<Checkbox>财务管理</Checkbox>)}*/}
                                </div>

                                <Form.Item>
                                    <Button style={{height: '2.5rem', marginTop: '4rem'}} type="primary"
                                            htmlType="submit"
                                            className="login-form-button" shape="round">
                                        立即申请
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>

                    </div>
                ) : (
                    <Login needNavigate={true}></Login>
                )}
            </div>

        )
    }
};

export default Form.create()(ProjectExp);