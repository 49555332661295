import {fetchData,fetchDataWithoutToken} from "../common/axios/request";
import cardEnum from "../component/enum/cardEnum";
import config from "../config";

// 获取已上线案例列表
export function fetchLaunchedCaseList(searchField,searchValue){
    let sortField;
    let searchList;
    let caseId = null;
    sortField = "caseId DESC"; //ASC - 升序， DESC -降序
    if(searchField){
        searchList = {
            "caseId": caseId,
            [searchField]:searchValue
        };
    }else {
        searchList = {
            "caseId": caseId,
        };
    }
    let jsonData = {
        "viewIndex": 1,  //start from 1
        "viewSize": 20,
        "sortField": sortField,
        "searchList": searchList
    };

   return fetchDataWithoutToken('wbGetLaunchedCaseList', JSON.stringify(jsonData))


}

//获取已上线案例详情
export function fetchLaunchedCaseDetail() {
    let caseId = "CS2023070510001";
    let jsonData = {
        "caseId": caseId
    };
    return fetchDataWithoutToken('wbGetCase', JSON.stringify(jsonData))
}


//获取已上线方案列表（解决方案菜单列表）
export function fetchLaunchedSolutionList() {
    let sortField;
    let searchList;
    let solutionId = null;
    sortField = "solutionId DESC"; //ASC - 升序， DESC -降序
    searchList = {
        "solutionId": solutionId
    };
    let jsonData = {
        "viewIndex": 1,  //start from 1
        "viewSize": 20,
        "sortField": sortField,
        "searchList": searchList
    };
    return fetchDataWithoutToken('wbGetLaunchedSolutionList', JSON.stringify(jsonData))
}


// wbGetSolution, 获取方案详情
export function fetchSolutionDetail() {
    let solutionId = "SL2023071110001";
    let jsonData = {
        "solutionId": solutionId
    };
    return fetchDataWithoutToken('wbGetSolution', JSON.stringify(jsonData))
}


//获取省列表
export function fetchProvinceList(geoPid)
{
    let sortField;
    let searchList;
    sortField = "geoPid DESC"; //ASC - 升序， DESC -降序
    searchList = {
        "geoPid": geoPid,
        "geoName": ""
    };
    let jsonData = {
        "viewIndex": 1,  //start from 1
        // "viewSize": 20,
        "sortField": sortField,
        "searchList": searchList
    };
    return fetchDataWithoutToken('getProvinceList', JSON.stringify(jsonData))
}


//获取市列表
export function fetchCityList(provinceGeoId)
{
    let sortField;
    let searchList;
    sortField = "geoPid DESC"; //ASC - 升序， DESC -降序
    searchList = {
        "provinceGeoId": provinceGeoId,
        "geoName": ""
    };
    let jsonData = {
        "viewIndex": 1,  //start from 1
        // "viewSize": 20,
        "sortField": sortField,
        "searchList": searchList
    };
    return fetchDataWithoutToken('getCityList', JSON.stringify(jsonData))

}


//获取区县列表
export function fetchDistrictList(cityGeoId)
{
    let sortField;
    let searchList;
    sortField = "geoPid DESC"; //ASC - 升序， DESC -降序
    searchList = {
        "cityGeoId": cityGeoId,
        "geoName": ""
    };
    let jsonData = {
        "viewIndex": 1,  //start from 1
        // "viewSize": 20,
        "sortField": sortField,
        "searchList": searchList
    };

    return fetchDataWithoutToken('getDistrictList', JSON.stringify(jsonData))

}

//获取用户信息
export function wbGetUser() {
    const partyId=sessionStorage.getItem('partyId')
    let jsonData = {
        "partyId": partyId  //用户名
    };
    return fetchData('wbGetUser', JSON.stringify(jsonData));
}


