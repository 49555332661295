import { observable, configure, action } from "mobx";

//开启严格模式，其他地方去修改store里的值会报错
configure({
    enforceActions: "always",
});
const mobxstore = observable(
    {
        list: [],
        projectType:observable([]),
        launchedSolutionList:[],
        menuList:[],
        setMenuList(itemList){
            this.menuList=itemList
        },
        setProjectType(projectTypeValues){
          this.projectType=observable(projectTypeValues);
        },
        setLaunchedSolutionList(value){
            this.launchedSolutionList=value
        }

    },
    {
        setProjectType:action,
        setLaunchedSolutionList:action,
        setMenuList:action
    }
);

export default mobxstore;
