import React from 'react';
import {
  Row,
  Col
} from 'antd';
import './style.css';
import banner from '../../statics/about/banner-career.png';

export default class JoinUs extends React.Component {

	componentDidMount () {
	  var BMap = window.BMap
	  var map = new BMap.Map("allmap"); // 创建Map实例
	  var point = new BMap.Point(121.5269030000,31.1820910000);
	  map.centerAndZoom(point, 19); // 初始化地图,设置中心点坐标和地图级别
	  map.addControl(new BMap.MapTypeControl()); //添加地图类型控件
	  map.setCurrentCity("上海"); // 设置地图显示的城市 此项是必须设置的
	  map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放

	  var marker = new BMap.Marker(point);  // 创建标注
		map.addOverlay(marker);              // 将标注添加到地图中

		var label = new BMap.Label("起典云",{offset:new BMap.Size(20,-10)});
		marker.setLabel(label);
	}
	render(){
		return(
			<div className="joinus">
				<Row type="flex" justify="center">
					<Col span={24} >
						<div className="banner-join"> 
							<img src={banner} alt="banner about us"/>
						</div>
					</Col>
				</Row>
				
				<div className="creer">
					<Row type="flex" justify="center">
						<Col span={14} >
							<h1>目标</h1>
							<h2>QDiCloud起典云 ，构建一个世界顶级的工程师开发团队。在这里，你可以开发世界优秀的APP、网站等。</h2>
						</Col>
					</Row>

					<Row type="flex" justify="center">
						<Col span={14} >
							<Col span={12}>
								<h1>核心价值</h1>
								<h2>我们所关注的</h2>
								<p>加入大咖团队， 一起工作， 一起解决高难度、有价值的问题</p>
							</Col>
							<Col span={12}>
								<h1>工作方法</h1>
								<h2>我们是这样工作的</h2>
							<p>一好的工作意味着为员工提供一个能茁壮成长的环境。我们致力<br />于以简单的创意带来巨大的冲击力。</p>
							</Col>							
						</Col>
					</Row>

					<Row type="flex" justify="center">
						<Col span={14} >
							<Col span={12}>
								<h2><span>01</span>直率且友善的态度</h2>
								<h2><span>02</span>深思熟虑而后行</h2>
								<h2><span>03</span>做行动的巨人</h2>
								<h2><span>04</span>有远大的志向</h2>
								<h2><span>05</span>坚持不懈的工作态度</h2>
								<h2><span>06</span>有主人翁精神</h2>
								<h2><span>07</span>以客户为中心</h2>
							</Col>
							<Col span={12}>
								<h2><span>01</span>今日事今日毕</h2>
								<h2><span>02</span>自动化无处不在</h2>
								<h2><span>03</span>交付令人惊喜的用户体验</h2>
								<h2><span>04</span>保持好关系 > 做个好交易</h2>
								<h2><span>05</span>多角度思考</h2>
								<h2><span>06</span>永远想最好的主意</h2>
								<h2><span>07</span>专注</h2>
							</Col>							
						</Col>
					</Row>
				</div> 

				<div className="position">
					<Row type="flex" justify="center">
						<Col span={14} >
							<h1>全职职位</h1>
							<Col span={12}>
								<h2>市场部</h2>
								<a href="/detailjoin"><p>销售经理</p></a>
								<p>市场开发经理</p>

								<h2>设计部</h2>
								<p>UI设计师</p>
								<p>视觉设计师</p>
							</Col>
							<Col span={12}>
								<h2>开发部</h2>
								<p>软件工程师-全栈</p>
								<p>软件工程师-后端</p>
								<p>软件工程师-前端</p>
								<p>软件质量工程师</p>
								<p>项目经理</p>
							</Col>							
						</Col>
					</Row>
				</div>

				<div className="address">
					<Row type="flex" justify="center">
						<Col span={14} >
							<Col span={12}>
								<h1>公司地址:</h1>
								<p>上海市浦东新区杨高南路2875号 康琳创意园1号楼 3001室</p>
							</Col>
							<Col span={12}>
								<div id="allmap"></div>
							</Col>							
						</Col>
					</Row>
				</div>

			</div>
		);
	}
}