import React from "react";

/**
 * 错误边界组件
 */
export default class ErrorBoundary extends React.Component{

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }


    componentDidCatch(error, info) {
        this.setState({ hasError: true, errorComponent:<div>发生错误的组件：{info.componentStack}</div> });
        console.log('may be arguments is wrong for component')
    }

    render(){
        if (this.state.hasError) {
            return(
            <div style={{textAlign:'center',paddingTop:'1rem',paddingBottom:'1rem',color:'red'}}>
                {this.state.errorComponent}
            </div>
            )
        }else {
            return this.props.children;
        }
    }

}