import React, { Component } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { Layout } from "antd";
import Head from "./common/header";
import Foot from "./common/footer";
import Index from "./pages/index";
import Login from "./pages/login";
import Register from "./pages/register";
import Myaccount from "./pages/myaccount";
import ForgetPwd from "./pages/forgetpwd";
import UpdatePwd from "./pages/upatepwd";
import AboutUs from "./pages/aboutus";
import JoinUs from "./pages/joinus";
import DetailJoin from "./pages/joinus/detailjoin";
import Coperate from "./pages/cooperate";
import AccessGuide from "./pages/accessGuide";
import ProjectExp from "./pages/projectExp";
import SolutionHead from "./pages/solution";
import SolutionHead2 from "./pages/solution2";
import SolutionHead3 from "./pages/solution3";
import SolutionHead4 from "./pages/solution4";
import SolutionHead5 from "./pages/solution5";
import Customer from "./pages/customer/customer";
import MyProject from "./pages/myproject";
import LoginDemand from "./pages/logindemand";
import CaseDetail from './pages/solution/caseDetail';
import SolutionCase from './pages/solution/solutionCase';
import ProductDisplay from "./pages/productDisplay";
import ScrollToTop from "../src/component/ScrollToTop/index";
import "./app.css";
import "./antd-theme/index.css"
import config from './config'
const { Header, Footer, Content } = Layout;

class App extends Component {

  render() {
    return (
      <Router>
        <Layout style={{flexDirection: 'column',width:window.innerWidth-17+'px'}}>
          <Header className="header">
            <Head/>
          </Header>
          <Content className="header">
            <ScrollToTop>
            <Switch>
              <Route path="/index" exact component={Index} />
              <Route
                path="/"
                exact
                render={() => <Redirect to="/index" push />}
              />
              <Route path="/login" exact component={Login} />
              <Route path="/register" exact component={Register} />
              <Route path="/myaccount" exact component={Myaccount} />
              <Route path="/forgetpwd" exact component={ForgetPwd} />
              <Route path="/updatepwd" exact component={UpdatePwd} />
              <Route path="/about" exact component={AboutUs} />
              <Route path="/join" exact component={JoinUs} />
              <Route path="/detailjoin" exact component={DetailJoin} />
              <Route path="/copoperate" exact component={Coperate} />
              <Route path="/access" exact component={AccessGuide} />
              <Route path="/project" exact component={ProjectExp} />
              <Route path="/product" exact component={ProductDisplay} />
              <Route path="/solution_ec" exact component={SolutionHead} />
              <Route path="/solution_point_reward" exact component={SolutionHead2} />
              <Route path="/solution_new_retail" exact component={SolutionHead3} />
              <Route path="/solution_wms" exact component={SolutionHead4} />
              <Route path="/solution_tms" exact component={SolutionHead5} />
              <Route path="/customer" exact component={Customer}  />
              <Route path="/myproject" exact component={MyProject} />
              <Route path="/logindemand" exact component={LoginDemand} />
              <Route path="/solution/case" exact component={SolutionCase} />
              <Route path="/solution/case/detail" exact  component={CaseDetail} />

              {/*{menu&&menu.map((route, index) => (*/}
              {/*    <Route key={index} path={route.routeUrl} component={route.component} />*/}
              {/*))}*/}
            </Switch>
            </ScrollToTop>
          </Content>
          <Footer className="footer">
            <Foot />
          </Footer>
        </Layout>
      </Router>
    );
  }
}

export default App;
