import React, {Component} from 'react';
import {
    Row,
    Col,
    Button,
    Form,
    Input,
    Avatar, Icon, Select, notification
} from 'antd';
import './style.css';
import {Link} from "react-router-dom";
import {fetchCityList, fetchDistrictList, fetchProvinceList,wbGetUser} from "../../actions/fetchDataAction";
import {wbUpdateUserSetting} from '../../actions/postAction';
const FormItem = Form.Item;

class Myaccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            provinceList: [],
            cityList: [],
            districtList: [],
            provinceGeoId: '',
            cityGeoId: '',
            userInfo:{},
        }

    }

    onFinish = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                let dataSet = {
                    userLoginId: values.userLoginId,
                    firstName: values.firstName,
                    phoneNumber: values.phoneNumber,
                    // currentPassword:values.currentPassword,
                    // newPassword:values.newPassword,
                    // newPasswordVerify:values.newPasswordVerify,
                    entPartyName: values.entPartyName,
                    entProvinceGeoId: values.province,
                    entCityGeoId: values.city,
                    entCountyGeoId: values.district,
                    entPostalAddress: values.postalAddress
                }
                wbUpdateUserSetting(dataSet).then((res)=>{
                    this.openNotificationWithIcon('success','数据更新成功')
                })

            }else {
                this.openNotificationWithIcon('error','请完善表单信息')
            }
        })
    };

    openNotificationWithIcon = (type, desc) => {
        notification[type]({
            message: '提示',
            description: desc,
        });
    };

    componentDidMount() {

        //获取用户信息
        wbGetUser().then((res)=>{
            let userInfo=res.data.data.itemInfo;
            this.setState({userInfo:userInfo})

            this.changeSelect(userInfo.entProvinceGeoId,1)
            this.changeSelect(userInfo.entCityGeoId,2)
            this.props.form.setFieldsValue({
                userLoginId:userInfo.userLoginId,
                firstName: userInfo.firstName,
                phoneNumber: userInfo.phoneNumber,
                // currentPassword:userInfo.currentPassword,
                // newPassword:userInfo.newPassword,
                // newPasswordVerify:userInfo.newPasswordVerify,
                entPartyName: userInfo.entPartyName,
                province: userInfo.entProvinceGeoId,
                city: userInfo.entCityGeoId,
                district: userInfo.entCountyGeoId,
                postalAddress: userInfo.entPostalAddress

            });
        })

        fetchProvinceList().then((res) => {
            if (res.data && res.data.data) {
                this.setState({provinceList: res.data.data.itemList})
            }

        })
    }


    getCityList(provinceGeoId) {
        fetchCityList(provinceGeoId).then((res) => {
            if (res && res.data && res.data.data) {
                this.setState({cityList: res.data.data.itemList})
            }

        })
    }

    getDistrictList(cityGeoId) {
        fetchDistrictList(cityGeoId).then((res) => {
            if (res && res.data && res.data.data) {
                this.setState({districtList: res.data.data.itemList})
                // console.log(res)
            }

        })
    }


    changeSelect = (value, level) => {
        if (level == 1) {
            this.setState({provinceGeoId: value})
            //修改了省，需要清空市和区
            this.props.form.resetFields(['city', 'district']);
            if (value) {
                //重新获取市的数据
                this.getCityList(value)
            } else {
                //value不存在，说明是手动清空了select，所以设置cityList,districtList为空
                this.setState({cityList: [], districtList: []})
            }


        } else if (level == 2) {

            this.setState({cityGeoId: value})
            //修改了市，需要清空区
            this.props.form.resetFields(['district']);
            if (value) {
                //重新获取区的数据
                this.getDistrictList(value)
            } else {
                //value不存在，说明是手动清空了select，所以设置districtList为空
                this.setState({districtList: []})
            }

        }

    }


    validatePassword = (_, value) => {
        let confirmPassword;
        if (_.field == 'newPasswordVerify') {
            confirmPassword = this.props.form.getFieldValue('newPassword');

        } else if (_.field == 'newPassword') {
            confirmPassword = this.props.form.getFieldValue('newPasswordVerify');
        }
        if (value && confirmPassword && value !== confirmPassword) {
            return Promise.reject(new Error('两次输入的密码不一致'));
        }

        return Promise.resolve();
    };


    //当输入了省份时，没填公司，则会提示需要填写公司
    validateParty = (_, value) => {
        const province=this.props.form.getFieldValue('province');//获取公司输入框内的值
        if(!value&&province){
            return Promise.reject(new Error('公司需要填写'));
        }
        return Promise.resolve();
    }


    render() {
        const {provinceList, cityList, districtList,userInfo} = this.state
        const {getFieldDecorator} = this.props.form;
        const {Option} = Select;
        return (
            <div className="account">
                <Row type="flex" justify="center">
                    <Col span={7}>
                        <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '1rem'}}>
                            <div style={{fontWeight: 'bolder', fontSize: '1.5rem'}}>我的账号</div>
                        </div>

                        <Form layout='vertical' className='myAccount-form' onSubmit={this.onFinish} >

                            <div className='myAccount-avatar'>
                                <Avatar shape="circle" size={108} icon="user"/>
                                <div className='myAccount-avatar-edit'>编辑</div>
                            </div>
                            <FormItem
                                label="登录时的用户名"
                            >
                                {getFieldDecorator('userLoginId')(
                                    <Input
                                        className="input"
                                        placeholder="用户名"
                                        disabled={true}
                                        allowClear={true}
                                    />
                                )}
                            </FormItem>

                            {/*<FormItem*/}
                            {/*    label="密码"*/}
                            {/*>*/}
                            {/*    {getFieldDecorator('password')(*/}
                            {/*        <Input.Password*/}
                            {/*            className="input"*/}
                            {/*            type="currentPassword"*/}
                            {/*            // onChange={value => this.handleChange('password', value)}*/}
                            {/*            placeholder="请输入您的密码"*/}
                            {/*        />)}*/}
                            {/*</FormItem>*/}

                            {/*<FormItem*/}
                            {/*    label="新密码"*/}
                            {/*>*/}
                            {/*    {getFieldDecorator('newPassword', {*/}
                            {/*        rules: [{validator: this.validatePassword}],*/}
                            {/*    })(*/}
                            {/*        <Input.Password*/}
                            {/*            className="input"*/}
                            {/*            type="password"*/}
                            {/*            // onChange={value => this.handleChange('password', value)}*/}
                            {/*            placeholder="请输入您的密码"*/}
                            {/*        />)}*/}
                            {/*</FormItem>*/}

                            {/*<FormItem*/}
                            {/*    label="重输新密码"*/}
                            {/*>*/}
                            {/*    {getFieldDecorator('newPasswordVerify', {*/}
                            {/*        rules: [{validator: this.validatePassword}],*/}
                            {/*    })(*/}
                            {/*        <Input.Password*/}
                            {/*            className="input"*/}
                            {/*            type="password"*/}
                            {/*            // onChange={value => this.handleChange('password', value)}*/}
                            {/*            placeholder="请输入您的密码"*/}
                            {/*        />)}*/}
                            {/*</FormItem>*/}

                            <FormItem
                                label="姓名（必填）"
                            >
                                {getFieldDecorator('firstName', {
                                    rules: [{required: true, message: '该项是必填选项'}],
                                })(
                                    <Input
                                        className="input"
                                        placeholder="请输入您的姓名"
                                        allowClear={true}
                                    />,
                                )}
                            </FormItem>


                            <FormItem
                                label="手机号码"
                            >
                                {getFieldDecorator('phoneNumber')(
                                    <Input
                                        className="input"
                                        placeholder="请输入您的手机号"
                                        allowClear={true}
                                    />,
                                )}
                            </FormItem>

                            <FormItem
                                label="公司"
                            >
                                {getFieldDecorator('entPartyName',{
                                    rules: [{validator: this.validateParty}],
                                })(
                                    <Input
                                        className="input"
                                        placeholder="请输入您的公司名称"
                                        allowClear={true}
                                    />,
                                )}
                            </FormItem>


                            <Form.Item label="省">
                                {getFieldDecorator('province')(
                                    <Select
                                        allowClear={true}
                                        showSearch
                                        placeholder="请输入"
                                        optionFilterProp="children"
                                        onChange={(value) => {
                                            this.changeSelect(value, provinceList[0] && provinceList[0].level)
                                        }}
                                    >
                                        {provinceList && provinceList.map((item) => (
                                            <Option value={item.geoId} key={item.webKey}>{item.geoName}</Option>
                                        ))}
                                    </Select>,
                                )}
                            </Form.Item>

                            <Form.Item label="市">
                                {getFieldDecorator('city')(
                                    <Select
                                        ref={(e) => this.city = e}
                                        // loading="true"
                                        allowClear={true}
                                        showSearch
                                        placeholder="请输入"
                                        optionFilterProp="children"
                                        onChange={(value) => {
                                            this.changeSelect(value, cityList[0] && cityList[0].level)
                                        }}
                                    >
                                        {cityList && cityList.map((item, index) => (
                                            <Option value={item.geoId} key={item.webKey}>{item.geoName}</Option>
                                        ))}
                                    </Select>,
                                )}
                            </Form.Item>


                            <Form.Item label="区">
                                {getFieldDecorator('district')(
                                    <Select
                                        allowClear={true}
                                        showSearch
                                        placeholder="请输入"
                                        optionFilterProp="children"
                                    >
                                        {districtList && districtList.map((item, index) => (
                                            <Option value={item.geoId} key={item.webKey}>{item.geoName}</Option>
                                        ))}
                                    </Select>,
                                )}
                            </Form.Item>

                            <FormItem
                                label="联系地址"
                            >
                                {getFieldDecorator('postalAddress')(
                                    <Input
                                        className="input"
                                        placeholder="请输入您的联系地址"
                                        allowClear={true}
                                    />,
                                )}
                            </FormItem>

                            <FormItem>
                                <Button className="btn-login" type="primary" shape='round' htmlType="submit">保存</Button>
                            </FormItem>

                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Form.create()(Myaccount);