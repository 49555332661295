/**
 * 卡片种类枚举
 * @type {{IMAGE_TITLE_DETAIL: string, GREEN: string}}
 */
const CardType = {
    IMAGE_TITLE_DETAIL: 'IMAGE_TITLE_DETAIL',
    IMAGE_TITLE_IMAGE: 'IMAGE_TITLE_IMAGE',
    IMAGE_ICON_DETAIL:'IMAGE_ICON_DETAIL'
};

export default CardType;