import React  from 'react';
import { Button, Row, Col } from "antd";
import {wbGetCase,getTypeList} from "../../config/api";
// import { getCaseInfoById } from '../../config/api';
import './index.css'
import config from "../../config";
let caseId;

export default class CaseDetail extends React.Component {
    constructor(props){
        super(props);
        this.state={
            case:[]
        }
    }

    componentDidMount(){
        const { location } = this.props;
        let params = new URLSearchParams(location.search);
        let caseId=params.get("id");
        //获取案例详情
        wbGetCase(caseId).then((res)=>{
            this.setState({case:res.data.data.itemInfo})
             getTypeList({fieldName:'industryCategoryId'}).then((res)=>{
                let values=res.data.data.values;
                let industryCategoryMap=new Map();
                values.map((item,index)=>{
                    industryCategoryMap.set(item.key,item.caption)
                })
                 this.setState({industryCategoryMap})

             })
        })
        // getCaseInfoById({
        //     caseId:caseId
        // }).then(res=>{
        //     debugger;
        //     this.setState({
        //         case:res.content
        //     })
        // })
    }

    render(){
        const { location } = this.props;
        const{industryCategoryMap}=this.state;
        let params = new URLSearchParams(location.search);
        caseId=params.get("id");
        // console.log(this.state.case);
        let info= this.state.case;
        return(
            <div className="case-detail">
                <Row type="flex" justify="center">
                    <Col xl={16} lg={16} md={22} sm={22} xs={23}>
                        <Row type="flex" justify="start">
                            <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                                <Button><a href="javascript:history.go(-1);">返回</a></Button>
                            </Col>
                            <Col xl={{span:8,offset:6}} lg={{span:8,offset:6}} md={{span:8,offset:6}} sm={{span:10,offset:5}} xs={{span:12,offset:5}}>
                                <div className="case-detail-title">{info?info.caseName:''}</div>
                            </Col>
                        </Row>

                        <Row type="flex" justify="start" style={{paddingTop:'2em'}}>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <div className="case-detail-item">行业</div>
                                <div className="case-detail-itemName">{industryCategoryMap&&industryCategoryMap.get(info.industryCategoryId)}</div>
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <div className="case-detail-item">平台</div>
                                <div className="case-detail-itemName">{info&&info.softwarePlatform}</div>
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <div className="case-detail-item">技术</div>
                                <div className="case-detail-itemName">{info&&info.developmentLanguage}</div>
                            </Col>
                        </Row>

                        <div className="case-detail-img01">
                            {info&&info.detailImageUrl ? <img src={`${config.SERVER_URL}${info.detailImageUrl}`} alt="img01"/> :""}
                        </div>

                        <Row type="flex" justify="start" style={{paddingTop:'3em'}}>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <div className="case-detail-item">项目简介</div>
                                <div className="case-detail-itemName">{info?info.webDescription:''}</div>
                            </Col>
                        </Row>

                        <div className="case-detail-img01">
                            {/*{info&&info.detailImageUrl ? <img src={`http://192.168.1.185:8080${info.detailImageUrl}`} alt="img01"/> :""}*/}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}