import React from 'react';
import {Col, Row} from "antd";
import banner from "../../statics/solution3/banner_1.png";
import style from "./style.module.css";
import ShowAnimate from "../../common/showAnimate";
import CardWrapper from "../../component/CardComponent";
import solution from "../../statics/solution3/img_solution.svg";
import business from "../../statics/solution3/img_business scenario.svg";
import cardEnum from "../../component/enum/cardEnum";
import cardPic1 from "../../statics/solution3/advantage_ico_lens.svg";
import cardPic2 from "../../statics/solution3/advantage_ico_miniprog.svg";
import cardPic3 from "../../statics/solution3/advantage_ico_store.svg";
import cardPic4 from "../../statics/solution3/advantage_ico_mobile.svg";
import advantage from "../../statics/solution3/advantage_bg.png";
import function1 from '../../statics/solution3/function_img_1.svg';
import function2 from '../../statics/solution3/function_img_2.svg';
import case1 from "../../statics/solution3/scenario_1.png";
import case2 from "../../statics/solution3/scenario_2.png";
import case3 from "../../statics/solution3/scenario_3.png";
import case4 from "../../statics/solution3/scenario_4.png";
import buttonIco from "../../statics/solution/ico_fly_white.svg";
import ButtonWrapper from "../../component/ButttonComponent";
import ButtonType from "../../component/enum/buttonEnum";

const SolutionHead3 = () => {
    const cardObj = [
        {
            title: '导购货架', type: cardEnum.IMAGE_TITLE_DETAIL, titleStyle: {
                textAlign: 'center'
            }, images: cardPic1, imageStyle: {
                height: '3rem', width: '3rem', marginLeft: '6rem'
            }, detail: '员工拥有自己的小店，利用社交圈层，拓展线上线下客户群体，让服务更好地链接客户，提升业绩。'
        },
        {
            title: '小程序货架', type: cardEnum.IMAGE_TITLE_DETAIL, titleStyle: {
                textAlign: 'center'
            }, images: cardPic2, imageStyle: {
                height: '3rem', width: '3rem', marginLeft: '6rem'
            }, detail: '利用社群分销、朋友圈分享，突破实体门店局限，展现门店商品，引导线下体验，线上购买。'
        },
        {
            title: '微商城货架', type: cardEnum.IMAGE_TITLE_DETAIL, titleStyle: {
                textAlign: 'center'
            }, images: cardPic3, imageStyle: {
                height: '3rem', width: '3rem', marginLeft: '6rem'
            }, detail: '24小时在线售卖，满足客户多元化需求，送货到家，把消费体验延伸到家。'
        },
        {
            title: '直播货架', type: cardEnum.IMAGE_TITLE_DETAIL, titleStyle: {
                textAlign: 'center'
            }, images: cardPic4, imageStyle: {
                height: '3rem', width: '3rem', marginLeft: '6rem'
            }, detail: '不到店也能买到称心如意的商品,邀请客户进入直播间买买买。'
        }
    ]

    const caseArr = [
        {
            style: {
                backgroundImage: `url(${case1})`,
                backgroundSize: 'cover'
            }, title: '直营'
        },
        {
            style: {
                backgroundImage: `url(${case2})`,
                backgroundSize: 'cover'
            }, title: '经销'
        },
        {
            style: {
                backgroundImage: `url(${case3})`,
                backgroundSize: 'cover'
            }, title: '加盟'
        },
        {
            style: {
                backgroundImage: `url(${case4})`,
                backgroundSize: 'cover'
            }, title: '联营'
        }
    ]

    return (
        <div className="solutionHead">
            <Row type="flex" justify="center">
                <Col span={24}>
                    <div className="banner-join" style={{position: 'relative'}}>
                        <img src={banner} alt="banner solution"/>
                        <div className={style.fontTitle}>
                            <div className='banner-font'>新零售数字化门店解决方案</div>
                            <div className='banner-font-middle' style={{marginTop: '1rem',fontWeight:'500',fontSize:'24px'}}>助力传统零售业转型，实现
                                <br/>
                                消费者、线上商城、实体门店三位一体的零售生态闭环
                            </div>
                            <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                                <ButtonWrapper
                                    buttonStyle={{marginTop: '3.5rem'}}
                                    type={ButtonType.MIAN_FEI_SHI_YONG}
                                >免费试用</ButtonWrapper>
                                <div style={{width:'15px'}}></div>
                                <ButtonWrapper
                                    buttonStyle={{marginTop: '3.5rem'}}
                                    type={ButtonType.ZAI_XIAN_ZI_XUN}
                                >在线咨询</ButtonWrapper>
                            </div>

                        </div>


                    </div>
                </Col>
            </Row>

            <ShowAnimate>
                <CardWrapper
                    title="多门店电商解决方案"
                    detail="一套系统搞定：线上商城 + 社交分销 + 门店管理 + 进销存 + 线下收银
帮助企业实现门店线上线下业务融合"
                    imgSrc={solution}
                    cardStyle={{backgroundColor: 'white'}}
                />
            </ShowAnimate>

            <ShowAnimate>
                <CardWrapper
                    title="业务场景"
                    detail="满足多种业务运营模式，帮助企业解决不同阶段的痛点，沉淀企业私有数字资产"
                    imgSrc={business}
                    cardStyle={{backgroundColor: '#f4f5f5'}}
                />
            </ShowAnimate>

            <ShowAnimate>
                <CardWrapper
                    title="优势"
                    detail="帮你增加四个云货架，突破实体门店局限，24小时不打烊，拓展销售渠道。"
                    cardObj={cardObj}
                    cardStyle={{backgroundImage: `url(${advantage})`, backgroundSize: 'cover', color: 'white'}}
                />
            </ShowAnimate>

            <ShowAnimate>
                <CardWrapper
                    title="推荐功能"
                    detail="线上线下多门店、多渠道增长的数字化门店系统"
                    cardStyle={{backgroundColor: 'white'}}
                >
                    <Row>
                        <Col span={6}>
                            <strong style={{fontSize: '16px'}}>导购分销</strong>
                            <div style={{marginTop: '1rem'}}>
                                通过社交分销，从拉新、转化、复购等环节赋能门店每一个导购，开辟导购第二战场，提升导购的服务推广能力，为门店提效增值。
                            </div>
                        </Col>
                        <Col span={16} push={2}>
                            <img src={function1} style={{width: '100%'}}/>
                        </Col>
                    </Row>

                    <Row style={{marginTop: '3rem'}}>
                        <Col span={16}>
                            <img src={function2} style={{width: '100%'}}/>
                        </Col>

                        <Col span={6} push={2}>
                            <strong style={{fontSize: '16px'}}>进销存线上流程化管控</strong>
                            <div style={{marginTop: '1rem'}}>
                                商品状态智能预警，智能数据分析商品销售、库存，及时提醒补仓或清仓整合上下游供应链，协同调拨，支持虚拟库存购买，不占空间无囤货压力，直接一件代发。
                            </div>
                        </Col>

                    </Row>

                </CardWrapper>
            </ShowAnimate>

            <ShowAnimate>
                <CardWrapper
                    title="应用场景"
                    detail="满足各行业品牌连锁门店经营需求"
                    cardStyle={{paddingBottom: '2rem', backgroundColor: '#f4f5f5'}}
                >
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {caseArr && caseArr.map((item,index) => (
                            <div key={index} style={{...item.style, width: '14rem', height: '20rem', borderRadius: '10px'}}>
                                <div style={{
                                    color: "white",
                                    height: "inherit",
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-end'
                                }}>
                                    <div style={{fontWeight: "bolder", paddingBottom: '2rem'}}>{item.title}</div>
                                    {/*<div style={{fontSize:'12px',marginTop:'2rem'}}>{item.detail}</div>*/}

                                </div>

                            </div>
                        ))}
                    </div>
                </CardWrapper>
            </ShowAnimate>
        </div>
    );
}

export default SolutionHead3;
