import React from 'react';
import {Col, Row} from "antd";
import banner from "../../statics/solution5/banner_1.png";
import style from "./style.module.css";
import CardWrapper from "../../component/CardComponent";
import solutionBg from "../../statics/solution4/solution_bg.png";
import laptop from "../../statics/solution5/intro_laptop.png";
import introBg from "../../statics/solution5/intro_bg.png";
import featureBg from "../../statics/solution5/feature_bg.png";
import draw1 from "../../statics/solution5/feature_draw_1.svg";
import draw2 from "../../statics/solution5/feature_draw_2.svg";
import draw3 from "../../statics/solution5/feature_draw_3.svg";
import draw4 from "../../statics/solution5/feature_draw_4.svg";
import cardEnum from "../../component/enum/cardEnum";
import advantage1 from "../../statics/index_2/draw_01.svg";
import advantage2 from "../../statics/index_2/draw_02.svg";
import advantage3 from "../../statics/index_2/draw_03.svg";
import advantage4 from "../../statics/index_2/draw_04.svg";

import ico1 from "../../statics/solution5/function_ico_data.svg";
import ico2 from "../../statics/solution5/function_ico_integration.svg";
import ico3 from "../../statics/solution5/function_ico_order.svg";
import ico4 from "../../statics/solution5/function_ico_route.svg";
import ico5 from "../../statics/solution5/function_ico_cooperation.svg";
import ico6 from "../../statics/solution5/function_ico_trace.svg";
import ico7 from "../../statics/solution5/function_ico_cost.svg";
import ico8 from "../../statics/solution5/function_ico_analysis.svg";
import ico9 from "../../statics/solution5/function_ico_teammng.svg";
import ico10 from "../../statics/solution5/function_ico_flow.svg";
import buttonIco from "../../statics/index_2/button-ico.svg";
import ButtonType from "../../component/enum/buttonEnum";
import ButtonWrapper from "../../component/ButttonComponent";

const SolutionHead5 = () => {

    const cardObj = [
        {
            title: '主数据管理',
            boxStyle: {height: '15rem'},
            type: cardEnum.IMAGE_TITLE_DETAIL,
            titleStyle:{
                textAlign:'center'
            },
            images: ico1,
            imageStyle: {width: '100%', height: '40%'},
            detail: '参与者、地点、线路、设施、物料、日历'
        },
        {
            title: '集成管理',
            boxStyle: {height: '15rem'},
            type: cardEnum.IMAGE_TITLE_DETAIL,
            titleStyle:{
                textAlign:'center'
            },
            images: ico2,
            imageStyle: {width: '100%', height: '40%'},
            detail: '数据集成、消息集成'
        },
        {
            title: '订单管理',
            boxStyle: {height: '15rem'},
            type: cardEnum.IMAGE_TITLE_DETAIL,
            titleStyle:{
                textAlign:'center'
            },
            images: ico3,
            imageStyle: {width: '100%', height: '40%'},
            detail: '采购订单、运输订单'
        },
        {
            title: '运输计划优化',
            boxStyle: {height: '15rem'},
            type: cardEnum.IMAGE_TITLE_DETAIL,
            titleStyle:{
                textAlign:'center'
            },
            images: ico4,
            imageStyle: {width: '100%', height: '40%'},
            detail: '路由分段、智能配载、线路优化'
        },
        {
            title: '承运商协同',
            boxStyle: {height: '15rem'},
            type: cardEnum.IMAGE_TITLE_DETAIL,
            titleStyle:{
                textAlign:'center'
            },
            images: ico5,
            imageStyle: {width: '100%', height: '40%'},
            detail: '调度派车、管理考核、招标平台'
        },
        {
            title: '运输执行跟踪',
            boxStyle: {height: '15rem'},
            type: cardEnum.IMAGE_TITLE_DETAIL,
            titleStyle:{
                textAlign:'center'
            },
            images: ico6,
            imageStyle: {width: '100%', height: '40%'},
            detail: '运单管理、异常监控、跟踪查询、在途库存、回单管理'
        },
        {
            title: '费用核算',
            boxStyle: {height: '15rem'},
            type: cardEnum.IMAGE_TITLE_DETAIL,
            titleStyle:{
                textAlign:'center'
            },
            images: ico7,
            imageStyle: {width: '100%', height: '40%'},
            detail: '自动计费、自动对账、开票申请、自动付款、开票核销'
        },
        {
            title: '商业智能与分析',
            boxStyle: {height: '15rem'},
            type: cardEnum.IMAGE_TITLE_DETAIL,
            titleStyle:{
                textAlign:'center'
            },
            images: ico8,
            imageStyle: {width: '100%', height: '40%'},
            detail: '饼图、折线图、仪表盘、散点图、柱状图等'
        },
        {
            title: '车队管理',
            boxStyle: {height: '15rem'},
            type: cardEnum.IMAGE_TITLE_DETAIL,
            titleStyle:{
                textAlign:'center'
            },
            images: ico9,
            imageStyle: {width: '100%', height: '40%'},
            detail: '维修保养、车辆巡检、车队组织、费用报销'
        },
        {
            title: '工作流',
            boxStyle: {height: '15rem'},
            type: cardEnum.IMAGE_TITLE_DETAIL,
            titleStyle:{
                textAlign:'center'
            },
            images: ico10,
            imageStyle: {width: '100%', height: '40%'},
            detail: '流程定义、流程监控、待办事项、已办事项'
        },
    ]
    return (
        <div className="solutionHead">
            <Row type="flex" justify="center">
                <Col span={24}>
                    <div className="banner-join" style={{position: 'relative'}}>
                        <img src={banner} alt="banner solution"/>
                        <div className={style.fontTitle}>
                            <div className='banner-font'>
                                运输管理TMS解决方案
                            </div>
                            <div className='banner-font-middle' style={{marginTop: '1.5rem',fontSize:'32px',fontWeight:'300',textAlign:'center'}}>
                                向物流全场景数字化出发
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <ButtonWrapper
                                    buttonStyle={{marginTop: '7rem'}}
                                    type={ButtonType.MIAN_FEI_SHI_YONG_WHITE}
                                >免费试用</ButtonWrapper>
                                <div style={{width:'15px'}}></div>
                                {/*<ButtonWrapper*/}
                                {/*    buttonStyle={{marginTop: '7rem'}}*/}
                                {/*    type={ButtonType.ZAI_XIAN_ZI_XUN}*/}
                                {/*>在线咨询</ButtonWrapper>*/}
                            </div>

                        </div>


                    </div>
                </Col>
            </Row>

            <CardWrapper
                title="QDCloud TMS"
                detail="QDCloud TMS 运输管理系统致力于提供端到端的解决方案，通过与相关系统和IoT设备集成，提供了一个精细化实时协同信息平台，助力企业数字化升级，从而真正达到企业对于物流运输的合规可控及降本增效。"
                imgSrc={laptop}
                cardStyle={{
                    fontFamily:'AlibabaPuHuiTi',
                    backgroundImage: `url(${introBg})`,
                    backgroundSize: 'cover',
                    height: 'auto',
                    color: 'black'
                }}
            />

            <CardWrapper
                title="产品特性"
                cardStyle={{
                    backgroundImage: `url(${featureBg})`,
                    backgroundSize: 'cover',
                    height: 'auto',
                    color: 'white'
                }}
            >
                <div style={{color: 'white'}}>
                    <Row>
                        <Col span={12}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{textAlign: 'right', marginRight: '2rem', width: '45%'}}>
                                    <strong style={{fontSize: '20px',fontWeight:'bolder'}}>
                                        基于互联网的架构平台
                                    </strong>

                                    <div className={style.solutionDetail}>
                                        用互联网浏览器访问，无需安装客户端软件。高度的可扩展性、用户和使用量。减少实施和维护的时间及成本。
                                    </div>
                                </div>


                                <img src={draw1} style={{height: '11rem'}}/>

                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={{display: 'flex', alignItems: 'center'}}>

                                <img src={draw2} style={{height: '11rem'}}/>

                                <div style={{textAlign: 'left', marginLeft: '2rem', width: '45%'}}>
                                    <strong style={{fontSize: '20px',fontWeight:'bolder'}}>
                                        支持Saas云服务
                                    </strong>

                                    <div className={style.solutionDetail}>
                                        零部署、零维护、低成本、快速使用。自动升级，持续获得价值服务，稳定，安全有保障。
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div style={{height:'1rem'}}></div>
                    <Row style={{marginTop: '4rem', paddingBottom: '6rem'}}>
                        <Col span={12}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{textAlign: 'right', marginRight: '2rem', width: '45%'}}>
                                    <strong style={{fontSize: '20px',fontWeight:'bolder'}}>
                                        个性化客制
                                    </strong>

                                    <div className={style.solutionDetail}>
                                        用户、角色、权限的自我管理。每个用户可以自定义偏好。
                                    </div>
                                </div>


                                <img src={draw3} style={{height: '11rem'}}/>

                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={{display: 'flex', alignItems: 'center'}}>

                                <img src={draw4} style={{height: '11rem'}}/>

                                <div style={{textAlign: 'left', marginLeft: '2rem', width: '45%'}}>
                                    <strong style={{fontSize: '20px',fontWeight:'bolder'}}>
                                        全球性的数据模型
                                    </strong>

                                    <div className={style.solutionDetail}>
                                        多语言、多币种、多时区、多汇率的国际化设置。
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </CardWrapper>

            <CardWrapper
                title="系统功能"
                cardObj={cardObj}
                cardStyle={{
                    height: 'auto',
                    backgroundColor: 'white'
                }}
            />


        </div>
    );
}

export default SolutionHead5;
