import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import buttonIco from "../../statics/index_2/button-ico.svg";
import buttonIco2 from "../../statics/solution/ico_fly_white.svg";
import buttonIco3 from "../../statics/solution/ico_fly.svg";
import buttonIco4 from "../../statics/index_2/ico_msg.svg";
import ButtonType from "../enum/buttonEnum";
class ButtonWrapper extends Component {
    constructor(props) {
        super(props)
    }

    clickedButton=()=>{
        this.props.history.push('/project'); // 跳转到指定路径
    }

    chooseButtonType(type){
        const{ buttonStyle,children }=this.props
        switch (type) {
            case ButtonType.LI_JI_ZI_XUN:
                return(
                    <div className='banner-button' style={buttonStyle} onClick={()=>{this.clickedButton()}}>
                        <img src={buttonIco} style={{width: '32px', paddingRight: '8px'}}/>
                        <span>{children}</span>
                    </div>
                )
            case ButtonType.LI_JI_ZI_XUN_WHITE:
                return(
                    <div className='banner-button-white' style={buttonStyle} onClick={()=>{this.clickedButton()}}>
                        <img src={buttonIco4} style={{width: '32px', paddingRight: '8px'}}/>
                        <span>{children}</span>
                    </div>
                )
            case ButtonType.MIAN_FEI_SHI_YONG:
                return (
                    <div className='banner-button' style={buttonStyle} onClick={()=>{this.clickedButton()}}>
                        <img src={buttonIco2} style={{width: '32px', paddingRight: '8px'}}/>
                        <span>{children}</span>
                    </div>
                )
            case ButtonType.MIAN_FEI_SHI_YONG_WHITE:
                return (
                    <div className='banner-button-white' style={buttonStyle} onClick={()=>{this.clickedButton()}}>
                        <img src={buttonIco3} style={{width: '32px', paddingRight: '8px'}}/>
                        <span>{children}</span>
                    </div>
                )
            case ButtonType.ZAI_XIAN_ZI_XUN:
                return (
                    <div className='banner-button-noColor' style={buttonStyle}>
                        <img src={buttonIco} style={{width: '32px', paddingRight: '8px'}}/>
                        <span>{children}</span>
                    </div>
                )
            default :
                return(
                    <div className='banner-button' style={buttonStyle} onClick={()=>{this.clickedButton()}}>
                        <img src={buttonIco} style={{width: '32px', paddingRight: '8px'}}/>
                        <span>{children}</span>
                    </div>
                )
        }
    }

    render() {
        const{ buttonStyle,children,type }=this.props
        return <div>{this.chooseButtonType(type)}</div>
    }
}

export default withRouter(ButtonWrapper)
