import api from './api';
import axios from 'axios';
import $ from 'jquery';

export const fetchData = (url, params) => {
    return api.get(url, {
        params: {inParams: params},
        headers: {
            'Authorization': `${sessionStorage.getItem('token')}`, // 设置请求头中的Authorization字段为Bearer加上token值
            "Content-Type": "application/json;charset=UTF-8",
            "Accept": "*/*"
        }
    });  // 发起GET请求

};

export const fetchDataWithoutToken = (url, params) => {
    return api.get(url, {
        params: {inParams: params},
        headers: {
            // 'Authorization': `${sessionStorage.getItem('token')}`, // 设置请求头中的Authorization字段为Bearer加上token值
            "Content-Type": "application/json;charset=UTF-8",
            "Accept": "*/*"
        }
    });  // 发起GET请求

};

export const postData = (url, data) => {
    return api.post(url, JSON.stringify(data), {
        dataType: 'json',
        headers: {
            'Authorization':sessionStorage.getItem('token'),
            'Content-Type': 'application/json;charset=UTF-8',
        },

    });  // 发起POST请求
};


export const postDataWithoutToken = (url, data) => {
    return api.post(url, JSON.stringify(data), {
        dataType: 'json',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },

    });  // 发起POST请求
};