import './style.css';
import React from 'react';
import {Button, Menu, Pagination, Radio,ConfigProvider} from 'antd';
import {Link} from "react-router-dom";
import zhCN from 'antd/es/locale/zh_CN';
import {wbGetProjectList} from '../../config/api'
export default class MyProject extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            itemList:[],
            totalListSize:0,
            pageSize:10,
        }
    }

    componentDidMount() {
        wbGetProjectList().then((res)=>{
            if(res.data&&res.data.itemList){
                this.setState({...res.data})
            }
        })
    }

    changePage=(pageNumber)=>{
        const {size}=this.state;
        let viewIndex=pageNumber;
        let viewSize=size;
        wbGetProjectList(viewIndex,viewSize).then((res)=>{
            if(res.data&&res.data.itemList){
                this.setState({...res.data})
            }
        })
    }

    changeSize=(size)=>{
        this.setState({size})
        let viewIndex=1;
        let viewSize=size;
        wbGetProjectList(viewIndex,viewSize).then((res)=>{
            if(res.data&&res.data.itemList){
                this.setState({...res.data})
            }
        })

    }

    render() {
        const{itemList,totalListSize}=this.state
        return (
          <div>
              <ConfigProvider locale={zhCN}>
              <header className="myProject-head">
                  <span>我的项目</span>
                  <Link to="/logindemand" className="send-btn">
                      <Button type="primary" size="large"> 发布需求</Button>
                  </Link>
              </header>
              <div className="container ant-row-flex ant-row-flex-center">
                  <div className="ant-col-sm-20 ant-col-md-20 ant-col-lg-20 ant-col-xl-18">
                  <div className="ant-row-flex ant-row-flex-start" style={{marginLeft: '-16px', marginRight: '-16px'}}>
                      {itemList.length>0?itemList.map((item,index)=>(
                          <div key={index} className="project-list ant-col-sm-8 ant-col-md-8 ant-col-lg-8" style={{
                              paddingLeft: '16px', paddingRight: '16px',paddingTop:'16px',paddingBottom:'16px'
                          }}>
                              <div className='myProject-item'>
                                  <h1>{item.projectName}</h1>
                                  <h2>{item.createdDate}</h2>
                                  <div>{item.webDescription}</div>
                              </div>
                          </div>
                      )):''}


                  </div>
                  </div>
              </div>
              <footer style={{paddingBottom:'80px',display:'flex',justifyContent:'center',backgroundColor:'#fafafa'}}>
                  {totalListSize&&totalListSize>0?
                      <Pagination
                          showSizeChanger
                          onChange={(pageNumber)=>{
                              this.changePage(pageNumber)}}
                          onShowSizeChange={(pageNumber,size)=>{
                              this.changeSize(size)
                          }}
                          defaultCurrent={1}
                          total={totalListSize}
                  />:''}

              </footer>
              </ConfigProvider>
          </div>
        )
    }

}