import React, {Component} from 'react';
import createHistory from 'history/createBrowserHistory';
import {
    Row,
    Col,
    Button,
    Form,
    Input,
    message, Icon, Divider
} from 'antd';
import './style.css';
import {signIn} from "../../config/api";
import {Link} from "react-router-dom";
import weChatIco from "../../statics/register/ico_wechat.svg";
import {
    CloseOutlined
} from '@ant-design/icons';
import config from "../../config";

const FormItem = Form.Item;
const history = createHistory();

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            password: ''
        }

        message.config({
            maxCount: 1,
            key: Math.random(),
        });
        this.handleChange = this.handleChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }


    fetchQrcode = () => {
        let appId=config.APP_ID
        let redirectUrl='https%3A%2F%2Fwww.qdcloud.net%2F%23%2Findex'
        window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect`
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {showQrcode} = this.state
        return (
            <div className="login">
                <Row type="flex" justify="center">
                    <Col xs={22} sm={18} md={8} lg={6} xl={6}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingTop: '8rem',
                            paddingBottom: '1rem'
                        }}>
                            <div style={{fontWeight: 'bolder', fontSize: '1.5rem'}}>登录</div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Link to="/register">
                                    <span className='register-login'>注册</span>
                                    <span style={{color: '#20aee5'}}><Icon type="caret-right"/></span></Link>
                            </div>
                        </div>


                        <Form
                            layout='vertical'
                            onSubmit={this.handleSubmit}
                        >
                            <FormItem
                                name="phone"
                                label="用户名"
                            >
                                {getFieldDecorator('phone', {
                                    rules: [{required: true, message: '请输入用户名！'}],
                                })(
                                    <Input
                                        className="input"
                                        onChange={value => this.handleChange('phone', value)}
                                        placeholder="请输入用户名"
                                        allowClear={true}
                                    />
                                )}
                            </FormItem>

                            <FormItem
                                name="password"
                                label="登录密码"
                            >
                                {getFieldDecorator('password', {
                                    rules: [{required: true, message: '请输入密码！'}],
                                })(
                                    <Input.Password
                                        className="input"
                                        type="password"
                                        onChange={value => this.handleChange('password', value)}
                                        placeholder="请输入您的密码"
                                        // allowClear={true}
                                    />)}
                            </FormItem>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div style={{color: '#4C92FF', cursor: 'pointer', paddingBottom: '2rem'}}>
                                    <Link style={{color:'#000'}} to="/forgetpwd">忘记密码？</Link>
                                </div>
                                {/*<div style={{color: '#4C92FF', cursor: 'pointer', paddingBottom: '2rem'}}>验证码登录</div>*/}
                            </div>

                            <FormItem>
                                <Button
                                    htmlType="submit"
                                    className="btn-login"
                                    type="primary"
                                    shape='round'
                                    onClick={this.handleLogin}
                                >登录</Button>
                            </FormItem>

                            <Divider>第三方登录</Divider>

                            <Row type='flex' justify='center'>
                                <Col><img alt='' src={weChatIco} style={{cursor: 'pointer'}} onClick={() => {
                                    this.fetchQrcode()
                                }}/></Col>
                            </Row>


                            {/* <FormItem >*/}
                            {/*  <span className="register">新用户？<a href="/register">注册帐号</a></span>*/}
                            {/*</FormItem>*/}
                        </Form>
                    </Col>
                </Row>

                <div style={{zIndex: showQrcode ? 100 : -1}}>
                    <div style={{
                        zIndex: showQrcode ? 100 : -1,
                        top: '0',
                        position: 'fixed',
                        width: '100%',
                        height: '100vh',
                        backgroundColor: 'white',
                        // opacity: '0.4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div id="login_container" style={{opacity: showQrcode ? 1 : 0}}></div>
                    </div>
                    {showQrcode ?
                        <div style={{cursor: 'pointer', zIndex: 200, position: 'fixed', top: '60px', right: '60px'}}
                             onClick={() => {
                                 this.setState({showQrcode: false})
                             }}>
                            <Icon style={{fontSize: '40px'}} type="close"/></div> : ''}
                </div>
            </div>
        );
    }

    // 绑定表单值
    handleChange(key, e) {
        this.setState({
            [key]: e.target.value
        }, () => console.log(this.state));

    }

    handleLogin() {
        const {needNavigate} = this.props
        signIn({
            username: this.state.phone,
            password: this.state.password
        })
            .then(res => {
                window.sessionStorage.setItem("token", 'Bearer ' + res.data.token);
                window.sessionStorage.setItem('isLogged', true);
                console.log('sss',res)
                sessionStorage.setItem('partyId',res.data.partyId)
                if (res.data.roleTypeId === 'ROLE_SYS_ADMIN') {
                    window.sessionStorage.setItem('roleTypeId', res.data.roleTypeId)
                    // window.open("http://122.112.170.70:6002/"); //管理员登录之后跳转到后台
                }

                if (!needNavigate) {
                    history.replace('/');
                    history.go();
                } else {
                    history.go();
                }


            })
            .catch(error => {
                message.error(error.message)
                console.log(error.message);
            });
    }
}

export default Form.create()(Login)