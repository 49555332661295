/**
 * 轮播图组件
 */
import React from 'react';
import {Carousel, Col, Row} from 'antd';
import './style.css'
import indexImg from "../../statics/index_2/banner_01.png";
import speedIco from "../../statics/index_2/ico_speed.svg";
import lampIco from "../../statics/index_2/ico_lamp.svg";
import config from "../../config";
import {withRouter} from 'react-router-dom';


const CarouselWrapper = (props) => {
    const {productItemList, wrapperStyle, title, images, children, titlePosition, size} = props
    const displayWidth = 1200
    const renderDesc = (desc) => {
        let splitArr = desc.split('\n')
        return splitArr.map((item, index) => (
            <div key={'split_' + index}>{item}</div>
        ))
    }
    return (
        <div style={wrapperStyle}>
            {size != 'large' ? (
                <header className='hot-title' style={{
                    width: displayWidth + 'px',
                    marginLeft: (window.innerWidth - displayWidth) / 2 + 'px',
                    paddingTop: '4.5rem',
                    paddingBottom: '0rem',
                    textAlign: titlePosition == 'left' ? 'start' : ''
                }}>{title}</header>
            ) : ''}
            {children ? (
                <>
                    <Carousel autoplay>
                        {images && images.length > 0 && images.map((item) => {
                            return (
                                <Row span={24} key={item}>
                                    <Col span={13}>
                                        <div key={item}>
                                            <img src={item} alt="index-img"
                                                 style={{marginTop: '6rem', width: '76%', float: 'right'}}/>
                                        </div>
                                    </Col>

                                    <Col span={11}>
                                        <div style={{float: 'left'}}>{children}</div>
                                    </Col>
                                </Row>
                            )
                        })}
                    </Carousel>
                </>
            ) : (
                <div>
                    {size === 'large' ? (
                        <Carousel autoplay>
                            {productItemList && productItemList.length > 0 && productItemList.map((item) => {
                                return (
                                    <div key={item}>
                                        <div style={{
                                            // backgroundImage: 'url(' + config.SERVER_URL + item.originalImageUrl + ')',
                                            width: '100%',
                                            height: 'auto',
                                            position:'relative'
                                            // backgroundSize:'cover'
                                        }}>
                                            <img src={config.SERVER_URL + item.originalImageUrl} style={{position:'absolute',zIndex:-1,width:'100%'}} />
                                            <header className='hot-title' style={{
                                                color: 'black',
                                                width: displayWidth + 'px',
                                                marginLeft: (window.innerWidth - displayWidth) / 2 + 'px',
                                                paddingTop: '4.5rem',
                                                paddingBottom: '0rem',
                                                textAlign: titlePosition == 'left' ? 'start' : ''
                                            }}>{title}</header>
                                            <div style={{
                                                width: displayWidth + 'px',
                                                marginLeft: (window.innerWidth - displayWidth) / 2 + 'px',
                                            }}>
                                                <div style={{
                                                    textAlign: 'start',
                                                    marginTop: '4rem',
                                                    marginLeft: '0rem',
                                                    fontWeight: 600,
                                                    fontSize: '20px',
                                                    color: '#000000'
                                                }}>
                                                    <div>{item.webDescription}</div>
                                                </div>

                                                <div>
                                                    <div className='index-ico-desc'>{renderDesc(item.description)}
                                                        <div>
                                                            <div className='carousel-button' onClick={() => {
                                                                props.history.push('/project'); // 跳转到指定路径
                                                            }}>立即咨询
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*<div className='index-ico-desc'>- 订单批量审核，自动拆分、合并订单，及时识别异常</div>*/}
                                                    {/*<div className='index-ico-desc'>- 订单打印，结合云仓/实仓管理</div>*/}
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                )
                            })}
                        </Carousel>
                    ) : (<Carousel autoplay>
                        {productItemList && productItemList.length > 0 && productItemList.map((item) => {
                            return (
                                <div key={item}>
                                    <div style={{
                                        display: 'flex',
                                        width: displayWidth + 'px',
                                        justifyContent: 'center',
                                        marginLeft: (window.screen.width - displayWidth) / 2 + 'px'
                                    }}>
                                        <div>
                                            <div key={item}>
                                                <img src={config.SERVER_URL + item.originalImageUrl} alt="index-img"
                                                     style={{marginTop: '3rem', width: displayWidth / 2 + 68 + 'px'}}/>
                                            </div>
                                        </div>

                                        <div style={{width: displayWidth / 2 + 'px'}}>
                                            <div style={{marginLeft: '4rem'}}>
                                                <div style={{
                                                    textAlign: 'start',
                                                    marginTop: '4rem',
                                                    marginLeft: '0rem',
                                                    fontWeight: 600,
                                                    fontSize: '20px',
                                                    color: '#000000'
                                                }}>
                                                    <div>{item.webDescription}</div>
                                                </div>

                                                {/*<div className='index-ico-container'>*/}
                                                {/*    {item.skuProperty.skuPropList && item.skuProperty.skuPropList.map((skuItem, index) => (*/}
                                                {/*            <div key={index}>*/}
                                                {/*                <div className='index-ico-box'>*/}
                                                {/*                    <img*/}
                                                {/*                        src={config.SERVER_URL + (skuItem && skuItem.imageUrlList && skuItem.imageUrlList[0])}*/}
                                                {/*                        className='index-ico'/>*/}
                                                {/*                    <div*/}
                                                {/*                        className='index-ico-font'>{skuItem.productFeatureName}</div>*/}
                                                {/*                </div>*/}
                                                {/*            </div>*/}
                                                {/*        )*/}
                                                {/*    )}*/}
                                                {/*</div>*/}

                                                <div>
                                                    <div className='index-ico-desc'>{renderDesc(item.description)}
                                                        <div>
                                                            <div className='carousel-button' onClick={() => {
                                                                props.history.push('/project'); // 跳转到指定路径
                                                            }}>立即咨询
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/*<div className='index-ico-desc'>- 订单批量审核，自动拆分、合并订单，及时识别异常</div>*/}
                                                    {/*<div className='index-ico-desc'>- 订单打印，结合云仓/实仓管理</div>*/}
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </Carousel>)}
                </div>
            )}


        </div>
    );
}

export default withRouter(CarouselWrapper);
