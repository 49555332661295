import React, {useEffect, useState} from "react";
import {Row, Col} from "antd";
import "./style.css";
import {Link, withRouter} from "react-router-dom";
import moment from "moment";
import icon from '../../statics/media/lz.5e1afaf4.jpg';
import QRcode from '../../statics/foot/QRcode.png';
import ico_email from '../../statics/foot/ico_email.svg';
import ico_location from '../../statics/foot/ico_location.svg';
import ico_phone from '../../statics/foot/ico_phone.svg';
import mobxstore from "../../mobxStore/store";
import {reaction} from "mobx";

function Footer() {
    moment().format("YYYY")
    // let currentYear = moment().year(); // Number
    const [menuList, setMenuList] = useState([])

    useEffect(() => {
        reaction(() => mobxstore.menuList,
            () => {
                setMenuList(mobxstore.menuList)
            }
        )

    }, [])

    return (
        <footer id="footer" className="dark">
            <div key="a">
                <div className="footer-wrap">
                    <Row justify="center" key="c_1">
                        <Col lg={2} sm={24} xs={24}/>
                        <Col lg={3} sm={24} xs={24}>
                            <div
                                style={{
                                    cursor: 'pointer',
                                    paddingTop: '20px',
                                    paddingBottom: '20px',
                                    textAlign: 'start'
                                }}>
                                {/*<Link to="/index">首页</Link>*/}
                            </div>

                        </Col>
                    </Row>
                    <Row justify="center" key="c_2">
                        <Col lg={2} sm={24} xs={24}/>
                    </Row>
                    <Row justify="center" key="c_3">
                        <Col lg={3} sm={24} xs={24}/>

                        <Col lg={3} sm={24} xs={24}>
                            <div className="footer-center">
                                <div className='tail-title' style={{paddingBottom: '0.5rem', fontWeight: '600'}}>产品</div>
                                <div className='product-detail'>
                                    <Link to="/customer">成功案例</Link>
                                </div>
                                <div className='product-detail'>
                                    <Link to="/about">关于我们</Link>
                                </div>
                                <div className='product-detail'>
                                    <Link to="/copoperate">合作伙伴</Link>
                                </div>
                            </div>
                        </Col>


                        <Col lg={4} sm={24} xs={24}>
                            <div className="footer-center">
                                <div className='tail-title' style={{paddingBottom: '0.5rem', fontWeight: '600'}}>解决方案</div>
                                {menuList&&menuList.length>0&&menuList.map((item,index)=>(
                                    <div key={'menuList_'+index} className='solution-detail'>
                                        <Link to={item.routeUrl}>{item.solutionName}</Link>
                                    </div>
                                ))}

                            </div>
                        </Col>


                        <Col lg={6} sm={24} xs={24}>
                            <div className="footer-center info">
                                <div className='tail-title' style={{paddingBottom: '0.5rem', fontWeight: '600'}}>联系我们</div>
                                <div className='concat-detail'>
                                    <img src={ico_location} style={{marginRight:'13px'}}/>
                                    <a>地址：上海市浦东新区杨高南路2875号康琳创意园1号楼3001室
                                    </a>
                                </div>
                                <div className='concat-detail'>
                                    <img src={ico_phone} style={{marginRight:'10px'}}/>
                                    <a>电话：021-50868858</a>
                                </div>
                                <div className='concat-detail'>
                                    <img src={ico_email} style={{marginRight:'8px'}}/>
                                    <a>Email：support@qdcloud.net</a>
                                </div>
                            </div>
                        </Col>

                        <Col lg={3} sm={24} xs={24}>
                            <div className="footer-center info" style={{marginLeft: '8rem'}}>
                                <img src={QRcode}/>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row className="bottom-bar">
                    <Col sm={24}>
                        {/*<div>Copyright ©{currentYear} 上海起典软件技术有限公司 All rights reserved</div>*/}
                        <div>Copyright © 2020-2024 上海起典软件技术有限公司 All rights reserved</div>

                        <div style={{display:'flex',justifyContent:"center"}}>
                            <div>
                                <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
                                   rel="noopener noreferrer"
                                   style={{cursor: "pointer"}}>
                                    <img src={icon} style={{
                                        height: '18px',
                                        width: '18px',
                                        // marginTop: '-2px',
                                        marginRight: '4px',
                                        verticalAlign: 'top'
                                    }}/>沪ICP备16035406号-2

                                </a>
                            </div>
                            <div style={{padding:'0 0.4rem'}}>|</div>
                            <div>
                                <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
                                   rel="noopener noreferrer"
                                   style={{cursor: "pointer"}}>
                                    <img src={icon} style={{
                                        height: '18px',
                                        width: '18px',
                                        // marginTop: '-2px',
                                        marginRight: '4px',
                                        verticalAlign: 'top'
                                    }}/>沪ICP备16035406号-3

                                </a>
                            </div>
                        </div>



                    </Col>

                </Row>

            </div>
        </footer>
    );
}

export default withRouter(Footer);
