import React, {useEffect, useState} from "react";
import { Row, Col, Button } from "antd";
import banner from "../../statics/customer/case-2.png";
import "./style.css";
import {getTypeList, wbGetLaunchedCaseList,wbGetCase} from '../../config/api'
import config from "../../config";
import style from "../solution4/style.module.css";
import ShowAnimate from "../../common/showAnimate";
import CardWrapper from "../../component/CardComponent";
import cardEnum from "../../component/enum/cardEnum";
import classic1 from "../../statics/index_2/img_case_01.png";
import classicA from "../../statics/index_2/ico_QRcode.svg";
import classic2 from "../../statics/index_2/img_case_02.png";
import classicB from "../../statics/index_2/ico_QRcode.svg";
import classic3 from "../../statics/index_2/img_case_03.png";
import classicC from "../../statics/index_2/ico_QRcode.svg";
import classic4 from "../../statics/index_2/img_case_04.png";
import classicD from "../../statics/index_2/ico_QRcode.svg";
import PaddingWrapper from "../../component/PaddingComponent";
import paddingEnum from "../../component/enum/paddingEnums";
import {fetchLaunchedCaseList} from "../../actions/fetchDataAction";
import _ from 'lodash';

const Customer= () => {
    let cardObj=[
        {title:'供享生活',type:cardEnum.IMAGE_TITLE_IMAGE,images:classic1,images2:classicA,tag:'#百货商超'},
        {title:'照片管理系统',type:cardEnum.IMAGE_TITLE_IMAGE,images:classic2,images2:classicB,tag:'#其他'},
        {title:'艺泰乐器',type:cardEnum.IMAGE_TITLE_IMAGE,images:classic3,images2:classicC,tag:'#其他'},
        {title:'麦扣扣',type:cardEnum.IMAGE_TITLE_IMAGE,images:classic4,images2:classicD,tag:'#家居百货'},
    ]

    const [cardObj2, setCardObj2] = useState(cardObj);
    const [cardObjMenu,setCardObjMenu]=useState()

    const fetchTypeList=(fieldName)=>{
        getTypeList({fieldName}).then((res)=>{
            if(res.data&&res.data.data){
                setCardObjMenu(res.data.data.values)

                // fetchCaseList('industryCategoryId',res.data.data.values[0].key)
                fetchCaseList()
                // this.setState({cardObjMenu:res.data.data.values})
                // this.fetchCaseList('industryCategoryId',res.data.data.values[0].key);//获取已上线案例
            }
        })
    }

   let fetchCaseList = (searchField,searchValue) => {
        fetchLaunchedCaseList(searchField,searchValue).then((res) => {
            let result = res.data.data.itemList;
            let cardObj = _.map(result, ({caseName, caseImageUrl, customerLogoUrl, tagList,description}) => ({
                title: caseName, type: cardEnum.IMAGE_ICON_DETAIL,
                images: config.SERVER_URL + caseImageUrl, images2: config.SERVER_URL + customerLogoUrl
                , tag: tagList,description
            }));

            setCardObj2(cardObj)

        })
            .catch((e) => {
                console.log('获取已上线案例失败', e)
            })

    }

    useEffect(()=>{
            // fetchLaunchedCaseList().then((res) => {
            //     let result = res.data.data.itemList;
            //     let cardObj = _.map(result, ({ caseName,caseImageUrl,customerLogoUrl,tagList,description }) => ({ title:caseName, type: cardEnum.IMAGE_ICON_DETAIL,
            //         images:config.SERVER_URL+caseImageUrl,images2:config.SERVER_URL+customerLogoUrl,tag:tagList,description
            //     }));
            //
            //     setCardObj2(cardObj)
            //
            // })
            //     .catch((e) => {
            //         console.log('获取已上线案例失败', e)
            //     })

            fetchTypeList('industryCategoryId')  //获取成功案例菜单列表

    },[])
    return (
        <div>
            <div className="solutionHead">
                <Row type="flex" justify="center">
                    <Col span={24}>
                        <div style={{position: 'relative'}}>
                            <img src={banner} alt="banner solution" style={{width:'100%'}}/>
                            <div className={style.fontTitle}>
                                <div className='banner-font'>成就企业高效协同</div>
                                {/*<div style={{fontSize: '1rem', marginTop: '1rem'}}>*/}
                                {/*    对多行业、不同仓库业务类型实现统一的精细化管理和物流协同优化，提供完善的作业策略，支持多种拣货波次规则，集成RF手持设备应用。*/}
                                {/*</div>*/}

                            </div>

                        </div>
                    </Col>
                </Row>
                </div>

            <ShowAnimate>
                <CardWrapper
                    cardObj={cardObj2}
                    cardObjMenu={cardObjMenu}
                    fetchCaseList={fetchCaseList}
                    cardStyle={{backgroundColor:'white'}}
                    title='解决方案'
                    titlePosition='left'
                />
            </ShowAnimate>

            {/*<ShowAnimate>*/}
            {/*    <CardWrapper*/}
            {/*        cardObj={cardObj2}*/}
            {/*        title=''*/}
            {/*    />*/}
            {/*</ShowAnimate>*/}

            <div style={{height:'200px',backgroundColor:"white"}}></div>
        </div>
    );
}

export default Customer;


